/* eslint-disable class-methods-use-this */

/* eslint-disable no-underscore-dangle */

/* eslint-disable no-param-reassign */

/* eslint-disable prefer-destructuring */

/* eslint-disable no-console */
import { useContext } from 'react';
import {
  AuthContext,
  CentralProviderContext,
  CentralProviderState,
  CentralUserProfile,
  usePermissions,
} from '@michelin/central-provider';
import { CustomerData, IAccountType, UserProfile, customerQuery } from 'AuthUtils';
import ApolloClient, { OperationVariables, QueryOptions } from 'apollo-boost';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { sleep } from 'components/Types/Api';
import { ContactLocationRelationship } from './components/Contact/utils';
import GLOBAL_CONFIG from './global-variables';
import { ServiceOfferIds } from './prefs-and-service-offers';

interface Auth0Result {
  accessToken: string;
  idToken: string;
  idTokenPayload: any;
}
interface AuthState {
  authResult?: Auth0Result;
}
export interface Customer {
  hash_key: string;
  range_key: string;
  customer_name: string;
  customer_addr1: string;
  customer_addr2: string;
  customer_city: string;
  customer_state: string;
  customer_zip: string;
  customer_number: string;
  relationship: string;
  parent_company_number: string;
  home_office_number: string;
  bill_to_customer: string;
  ship_to_customer: string;
  customer_type: string;
  extrnl_cust_id: string;
  hold_code?: string;
  contacts?: [ContactLocationRelationship];
}

export interface RecursiveCustomerFleetAccount {
  customer: Customer;
  childAssets?: Array<RecursiveCustomerFleetAccount>;
}

interface ICustomerNumberType {
  customerNumber: string | undefined;
  customerType: string | undefined;
}

enum EAccountTypes {
  PC,
  HO,
  BT,
  ST,
}

export enum AuthUpdateEvent {
  SESSION_LOADED,
  CUSTOMER_NUMBER_SET,
}

export type AuthUpdateSubscriber = (event?: AuthUpdateEvent, userProfile?: Auth['userProfile']) => void;

/**
 * This class is in charge of loading all the data from the Authorization process.
 * How this works:
 * 1) User logs in via - CentralProvider/Auth0.
 * 2) <RenderLoggedApp> render method calls Auth.init() with the Central Provier Login Context.
 * 3.1) This class creates the apollo client
 * 3.2) This class sets the sesion filling some fields retrieved from Auth0 Payload Token like (Email, name, address, etc.)
 * 3.3) This class retrieves the main location customer data from the DB.
 * 3.4) Inform any callback subscribed to new data updates.
 */
export class Auth {
  private apiVersion: string | null;

  private multipleAccounts: boolean;

  private country_code: string | null;

  private error: { title: string; msg: string } | undefined;

  private grants: any[];

  private lastMutation: number;

  private loadingAccount: boolean;

  private suscribers: Array<AuthUpdateSubscriber>;

  private allowedAccounts: Array<Customer> = new Array<Customer>();

  private allowedLocations: Set<string>;

  private sessionLoaded: boolean = false;

  private loadingSession: boolean = false;

  apolloClient: ApolloClient<any> | null;

  private apolloHeaders: { authorization: string; authorizationtoken: string } | null;

  private allowedLocationsCache: Map<string, Promise<any>>;

  private centralProviderContext: CentralProviderState | null = null;

  private permissions: ReturnType<typeof usePermissions> | null = null;

  private authContext: AuthState | null = null;

  userProfile: UserProfile | null;

  constructor() {
    this.apiVersion = null;
    this.apolloClient = null;
    this.apolloHeaders = null;
    this.country_code = null;
    this.lastMutation = 0;
    this.loadingAccount = false;
    this.multipleAccounts = false;
    this.suscribers = [];
    this.userProfile = null;
    this.grants = [];
    this.allowedLocations = new Set();
    this.allowedLocationsCache = new Map<string, Promise<any>>();
  }

  private lastTokensValue = '';

  /**
   * Init the apollo client and Auth Class using the Central Provider Context.
   * @param centralProviderContext
   */
  init() {
    const centralProviderContext = useContext(CentralProviderContext);
    const authContext = useContext(AuthContext);
    const permissions = usePermissions();
    this.permissions = permissions;
    this.centralProviderContext = centralProviderContext;
    this.authContext = authContext;
    if (!authContext.authResult.idToken || !authContext.authResult.accessToken) return;
    const currentValue = `${authContext.authResult.idToken} / ${authContext.authResult.accessToken}`;
    if (this.lastTokensValue === currentValue) return;
    this.lastTokensValue = currentValue;
    this.createApolloClient();
    this.setSession();
  }

  public getAuthResultTokenAccess() {
    return this.authContext?.authResult?.accessToken || '';
  }

  private updateApolloClientHeaders() {
    const idToken = this.authContext?.authResult?.idToken;
    const bearerToken = this.authContext?.authResult?.accessToken;

    if (!idToken || !bearerToken) return;
    if (!this.apolloHeaders) this.apolloHeaders = { authorization: '', authorizationtoken: '' };
    this.apolloHeaders.authorization = idToken;
    this.apolloHeaders.authorizationtoken = `Bearer ${bearerToken}`;
  }

  /* Creates the apollo client used around the whole project. */
  async createApolloClient() {
    this.updateApolloClientHeaders();
    if (this.apolloClient) return;

    const cache = new InMemoryCache({});

    this.apolloClient = new ApolloClient({
      cache,
      uri: GLOBAL_CONFIG.graphQlUrl,
      headers: this.apolloHeaders,
    });

    const oldMutate = this.apolloClient.mutate;
    // @ts-ignore
    this.apolloClient.mutate = (options: MutationOptions<any, OperationVariables>) => {
      this.lastMutation = new Date().getTime();
      this.updateApolloClientHeaders();
      return oldMutate(options);
    };

    const oldQuery = this.apolloClient.query;
    // @ts-ignore
    this.apolloClient.query = (options: QueryOptions<OperationVariables>) => {
      this.updateApolloClientHeaders();
      return oldQuery(options);
    };
  }

  /* Sets the user session using the Central Provider context authorization result payload  */
  async setSession() {
    this.loadingSession = true;
    // centralProviderContext.
    const centralProfile = this.permissions?.profile;
    // I won't set session twice...
    if (this.userProfile && this.loadingAccount) return;
    if (!centralProfile) return;
    this.loadingAccount = true;
    try {
      this.setAccountLocations(centralProfile);
      // Before setting the user profile locations must be assigned to retrieve the customer data.
      await this.setUserProfile(centralProfile);
      // Before setting the allowed locations the user profile / customer number must be loaded.
      await this.setAllowedLocations();
      this.multipleAccounts = this.allowedLocations.size > 1;
      this.suscribers.forEach((e) => {
        e(AuthUpdateEvent.SESSION_LOADED, this.userProfile);
      });
      this.loadingAccount = false;
      this.loadingSession = false;
      this.sessionLoaded = true;
    } catch (e) {
      console.error('Error trying to set user account profile and locations permissions.', e);
    }
  }

  public usePermissions() {
    return this.permissions;
  }

  setAccountLocations(centralProfile: CentralUserProfile | undefined) {
    if (!centralProfile) return;
    const { grants } = centralProfile;
    if (grants) {
      grants.forEach((grant) => {
        const { role, accounts, location } = grant;
        if (role) {
          this.grants.push(grant);

          if (accounts) {
            accounts.forEach((allowedAccount) => {
              this.allowedAccounts.push(allowedAccount as unknown as Customer);
              this.allowedLocations.add(allowedAccount.customer_number);
            });
          }

          // If they have not been already settle with the Accounts then user the locations
          // B2B does not have any accounts. The logic is done here.
          if (!location?.locations) return;
          location.locations.forEach((locationNumber: string | number) => {
            if (!locationNumber) return;
            this.allowedLocations.add(locationNumber.toString());
          });
        }
      });
    }
  }

  async setUserProfile(centralProfile: CentralUserProfile) {
    if (this.userProfile) return;
    // Set user info
    if (centralProfile.auth0.name != null) {
      // These fields are from Auth0
      const email = centralProfile.email;
      const language = centralProfile.profile.language;
      const name = centralProfile.auth0.name;
      const userRole = centralProfile.master_role;
      const userId = centralProfile.id.toString();
      let shipTos: string[] | null = null;
      let customerData = null;

      const allowedLocationsArr = Array.from(this.allowedLocations.values()).sort();

      if (
        !this.centralProviderContext?.hasFullAccountAccess('fleets') &&
        allowedLocationsArr &&
        allowedLocationsArr.length > 0
      ) {
        // This customer data is from our DB
        customerData = await this.getAccountData();
        if (customerData && customerData.customer_type === 'ST' && this.allowedLocations.size > 1) {
          shipTos = Array.from(this.allowedLocations.values());
        } else if (customerData && customerData.customer_type === 'ST' && this.allowedAccounts.length > 1) {
          shipTos = this.allowedAccounts.map((x) => x.customer_number);
        }
      }
      this.userProfile = {
        email,
        language,
        name,
        userRole,
        userId,
        customerData,
        mainCustomerData: customerData,
        shipTos,
      };
    }
  }

  /**
   * Returns the allowed account locations
   */
  private async setAllowedLocations(customerNumber?: string): Promise<string[] | null> {
    if (!this.apolloClient) return null;
    if (!customerNumber) customerNumber = this.getCustomerNumber();
    // If grant accounts where available. Then Allowed Locations should be already loaded.
    // if(this.allowedAccounts.length > 0) return null;
    const endpoint = `${process.env.REACT_APP_API_BASE_URL}/central/users/accounts?root=${customerNumber}&$limit=10000&app_key=core_profile&_source=customer_number`;

    try {
      const accountType = this.getAccountTypeShort();
      switch (accountType) {
        case 'ST':
          this.allowedLocations.add(customerNumber);
          return [customerNumber];
        case 'PC':
          break;
        case 'HO':
          break;
        case 'BT':
          break;
        default:
          return [];
      }

      let res = this.allowedLocationsCache.get(endpoint);
      if (!res) {
        res = new Promise((accept, reject) => {
          fetch(endpoint, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${this.getAuthResultTokenAccess()}`,
            },
          })
            .then((r) => r.json().then(accept).catch(reject))
            .catch(reject);
        });
        this.allowedLocationsCache.set(endpoint, res);
      }
      const data = await res;
      const locations = data.data.map((hit: any) => {
        this.allowedLocations.add(hit.customer_number);
        return hit.customer_number;
      });
      return locations;
    } catch (e) {
      this.allowedLocationsCache.delete(endpoint);
      return [];
    }
  }

  private setCustomerNumberSemaphore = 0;

  /* Sets the customer number and refilles the customer data if it's necessary */
  async setCustomerNumber(customerNumber: string, setLoading: (loading: boolean) => void) {
    this.loadingAccount = true;
    this.setCustomerNumberSemaphore++;
    // eslint-disable-next-line no-await-in-loop
    while (this.setCustomerNumberSemaphore > 1) await sleep(10);
    if (!this.userProfile) return;
    setLoading(true);
    this.userProfile.customerData = await this.getAccountData(customerNumber);
    // This happens when user access as Michelin Employee it will have no Main Customer Data
    if (this.centralProviderContext?.hasFullAccountAccess('fleets')) {
      await this.setAllowedLocations();
      this.userProfile.mainCustomerData = this.userProfile.customerData;
    }
    this.suscribers.forEach((e) => {
      e(AuthUpdateEvent.CUSTOMER_NUMBER_SET, this.userProfile);
    });
    this.loadingAccount = false;
    this.setCustomerNumberSemaphore--;
    if (this.setCustomerNumberSemaphore === 0) setLoading(false);
  }

  /* Returns the customer data. The Location/Customer Data (1~Entity) */
  public async getAccountData(customerNumber?: string): Promise<CustomerData | null> {
    if (!this.apolloClient) return null;
    try {
      if (!customerNumber) {
        // eslint-disable-next-line no-await-in-loop
        while (!this.getCustomerNumber()) await sleep(10);
      }
      const data = await this.apolloClient.query({
        query: customerQuery,
        variables: { hash_key: `1~${customerNumber || this.getCustomerNumber()}` },
      });
      if (!data.data.getCustomerAssets.customer) return null;
      this.apiVersion = (data.data.getApiVersion || {}).version || null;
      const cd: CustomerData = data.data.getCustomerAssets.customer;
      if (cd.bill_to_customer === 'null') cd.bill_to_customer = null;
      if (cd.ship_to_customer === 'null') cd.ship_to_customer = null;
      if (cd.parent_company_number === 'null') cd.parent_company_number = null;
      if (cd.home_office_number === 'null') cd.home_office_number = null;
      if (!cd.customer_country) this.country_code = null;
      else this.country_code = cd.customer_country.toLowerCase();
      return cd;
      // eslint-disable-next-line no-empty
    } catch (e) {}
    return null;
  }

  /* Subscribes to listen an update in the User Profile data */
  suscribeUpdates(callback: AuthUpdateSubscriber) {
    this.suscribers.push(callback);
  }

  /* Unsuscribes from an update in the User Profile data */
  unSuscribeUpdates(callback: AuthUpdateSubscriber) {
    this.suscribers = this.suscribers.filter((a) => a !== callback);
  }

  /* Returns the customer name from the User Profile */
  getCustomerName(): string {
    return this.permissions?.location?.customer_dba_name || this.permissions?.location?.customer_name || '';
  }

  /* Returns the customer number from the User Profile */
  getCustomerNumber(): string {
    return this.permissions?.location?.customer_number || '';
  }

  /* Returns the customer number from the User Profile */
  getMainCustomerNumber(): string {
    if (!this.userProfile || !this.userProfile.mainCustomerData || !this.userProfile.mainCustomerData.customer_number)
      return '';
    return this.userProfile.mainCustomerData.customer_number;
  }

  /* Returns the customer number from the User Profile */
  getMainCustomerRelationship(): string {
    if (!this.userProfile || !this.userProfile.mainCustomerData || !this.userProfile.mainCustomerData.relationship)
      return '';
    return this.userProfile.mainCustomerData.relationship;
  }

  getCustomerData(): CustomerData | null {
    return (this.permissions?.location as unknown as CustomerData) || null;
  }

  getUserProfile(): UserProfile | null {
    return this.userProfile;
  }

  /* Returns the country code from the Customer Data */
  getCountryCode() {
    return this.country_code;
  }

  getServiceOffersSubscriptions(): string[] {
    /*
     ** This is a string array of service offer IDs
     ** that should come from B2B via Auth0
     */
    const serviceOffers: string[] = [
      ServiceOfferIds.allPreferences,
      ServiceOfferIds.onCall,
      ServiceOfferIds.bulkOrder,
    ];

    return serviceOffers;
  }

  /* Returns the relationship from the user Customer Data */
  getRelationship(): string {
    return this.permissions?.location?.relationship || '';
  }

  /* Returns the highest level parent in the hierarchy from the user Customer Data */
  getUltimateParent(): ICustomerNumberType {
    const customerNumberType: ICustomerNumberType = { customerNumber: undefined, customerType: undefined };
    const rel: string[] = this.getRelationship().split('~');
    for (let i = 0; i < 4; i++) {
      if (rel[i].length === 7) {
        customerNumberType.customerNumber = rel[i];
        customerNumberType.customerType = EAccountTypes[i];
        break;
      }
    }
    return customerNumberType;
  }

  /* Returns the Email from the Main User Profile */
  getEmail(): string {
    if (!this.userProfile || !this.userProfile.email) return '';
    return this.userProfile.email;
  }

  /* Different Account Types used in Core Profile */
  private _AccountTypes: IAccountType[] = [
    { hierarchyPosition: 4, shortName: 'pc', longName: 'parentCompany' },
    { hierarchyPosition: 3, shortName: 'ho', longName: 'homeOffice' },
    { hierarchyPosition: 2, shortName: 'bt', longName: 'billTo' },
    { hierarchyPosition: 1, shortName: 'st', longName: 'shipTo' },
  ];

  iOwnThisAccountType(name: string, mode?: string): number | undefined {
    // the reason to return a number rather than a boolean is because I want to check if a '0' is returned, e.g. PC === PC
    // then in the code calling this message should additionally check if the account numbers are equal, this will prevent
    // a BT level account from modifying another BT's data for example
    // mode: optional or 'switched' - missing would be mode = 'login'
    const accountType: string | null =
      mode && mode.toLowerCase() === 'switched' ? this.getAccountType() : this.getLoginAccountType();

    if (accountType !== null) {
      const myAccountType =
        this._AccountTypes.find((o) => o.longName.toLowerCase() === accountType.toLowerCase()) || undefined;
      if (name.length === 2) {
        const targetAccountType =
          this._AccountTypes.find((o) => o.shortName.toLowerCase() === name.toLowerCase()) || undefined;
        return myAccountType && targetAccountType
          ? myAccountType.hierarchyPosition - targetAccountType.hierarchyPosition
          : undefined;
      }
      const targetAccountType =
        this._AccountTypes.find((o) => o.longName.toLowerCase() === name.toLowerCase()) || undefined;
      return myAccountType && targetAccountType
        ? myAccountType.hierarchyPosition - targetAccountType.hierarchyPosition
        : undefined;
    }
    return undefined;
  }

  /**
   * Returns the Account Type from the Customer Data in the User Profile
   * @param customerType
   */
  getLoginAccountType(customerType?: string): 'homeOffice' | 'billTo' | 'shipTo' | 'parentCompany' | null {
    if (!this.userProfile) return null;
    if (!this.userProfile.mainCustomerData) return null;
    switch ((customerType || this.userProfile.mainCustomerData.customer_type || '').toLowerCase()) {
      case 'st':
        return 'shipTo';
      case 'bt':
        return 'billTo';
      case 'ho':
        return 'homeOffice';
      case 'pc':
        return 'parentCompany';
      default:
        return null;
    }
  }

  /**
   * Returns the Account Type from the Customer Data in the User Profile
   * @param customerType
   */
  getAccountType(customerType?: string): 'homeOffice' | 'billTo' | 'shipTo' | 'parentCompany' | null {
    if (!this.userProfile) return null;
    if (!this.userProfile.customerData) return null;
    switch ((customerType || this.userProfile.customerData.customer_type || '').toLowerCase()) {
      case 'st':
        return 'shipTo';
      case 'bt':
        return 'billTo';
      case 'ho':
        return 'homeOffice';
      case 'pc':
        return 'parentCompany';
      default:
        return null;
    }
  }

  /**
   * Returns the Account Type as Upper Case and shortened. Ex: [HO, PC, ST, BT]
   */
  getAccountTypeShort() {
    if (!this.userProfile) return null;
    if (!this.userProfile.customerData) return null;
    if (!this.userProfile.customerData.customer_type) return null;
    return this.userProfile.customerData.customer_type.toUpperCase();
  }

  /**
   * Returns the Account Type as Upper Case and shortened. Ex: [HO, PC, ST, BT]
   */
  getMainAccountTypeShort() {
    if (!this.userProfile) return null;
    if (!this.userProfile.mainCustomerData) return null;
    if (!this.userProfile.mainCustomerData.customer_type) return null;
    return this.userProfile.mainCustomerData.customer_type.toUpperCase();
  }

  /**
   * Sets an authorization error to be displayed in case if necessary.
   * @param title
   * @param msg
   */
  private setAuthError(title: string, msg: string) {
    this.error = { title, msg };
  }

  /**
   * Returns an error if there has been one in the Authorization process.
   */
  getError() {
    return this.error || null;
  }

  /**
   * Returns true if a location account number is in the allowed locations list.
   * @param accountNumber
   */
  isLocationAllowed(accountNumber: string): boolean {
    try {
      accountNumber = accountNumber.split('~').pop() || accountNumber;
      if (!this.userProfile || !accountNumber) return false;
      if (this.getCustomerNumber() === accountNumber) return true;
      if (this.allowedLocations.has(accountNumber)) return true;
    } catch (error) {
      console.error('Procesing location allowed');
      return false;
    }
    return this.allowedLocations.has(accountNumber);
  }

  /**
   * Returns the timestamp of the last mutation executed by the Apollo Client.
   */
  getLastMutation(): number {
    return this.lastMutation;
  }

  /* Returns the API Version in the Back-End */
  getApiVersion(): string | null {
    return this.apiVersion;
  }

  /** *
   *
   */
  isLoadingSession(): boolean {
    return this.loadingSession;
  }

  isSessionLoaded(): boolean {
    return this.sessionLoaded;
  }

  /**
   * Returns true if the selected location customer is a Urban location customer.
   */
  isUrbanCustomer(): boolean {
    const { userProfile } = this;

    if (userProfile) {
      const { customerData } = userProfile;
      if (customerData) {
        return customerData.is_urban === true;
      }
    }

    return false;
  }

  /**
   * Returns true if the selected location customer is a commercial location customer.
   */
  isCommercialCustomer(): boolean {
    const { userProfile } = this;

    if (userProfile) {
      const { customerData } = userProfile;
      if (customerData) {
        return customerData.is_commercial === true;
      }
    }

    return false;
  }
}

export const auth = new Auth();
