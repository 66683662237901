import React, { ChangeEvent, useEffect, useState } from 'react';
import { Grid, MenuItem } from '@material-ui/core';
import { LoadingBounce, Panel, Select, Switch, TextField } from '@michelin/acid-components';
import { useTranslation } from '@michelin/central-provider';
import { SelectOption, getOptions } from '@michelin/select-options-provider';
import { auth } from 'Auth';
import LocationAssignments from 'components/LocationAssignments';
import { IAssigneeCustomerType } from 'components/TireProfile/Assignee/AssigneeType';
import { SelectedServiceOffer, ServiceOfferIds, getSelectedServiceOffer } from '../../../../prefs-and-service-offers';
import {
  BillingGeneralInformation,
  BillingPreferences,
  BillingProfileLocation,
  BillingProfileTypes,
  BillingServiceTypes,
} from '../query';
import { ValidationErrors } from '../utils';
import { transformAsigneeToBillingLocation, transformBillingLocationToAsignee } from './utils';

interface GeneralInformationProps {
  profile: BillingPreferences;
  locations: BillingProfileLocation[];
  editFlag: boolean;
  loadingLocations: boolean;
  editProfile: Function;
  setModifiedFlag: (modifiedFlag: boolean) => void;
  editLocations: Function;
  onCallFlag: boolean;
  validationErrors: ValidationErrors;
  profileTypeHandler: (event: React.ChangeEvent<{ name?: string; value: unknown }>) => void;
  isUrbanCustomer: boolean;
}

export const getListProfileType = (): string => {
  let listProfileType = '';

  const locSelectedServiceOffer: SelectedServiceOffer | undefined = getSelectedServiceOffer() || undefined;
  switch (locSelectedServiceOffer && locSelectedServiceOffer.id) {
    case ServiceOfferIds.allPreferences:
      listProfileType = 'all';
      break;
    case ServiceOfferIds.onCall:
      listProfileType = 'ers';
      break;
    case ServiceOfferIds.bulkOrder:
      listProfileType = 'onsite';
      break;
    default:
      listProfileType = '';
      break;
  }

  return listProfileType;
};

export const mandatoryDefaultmessage = (t: Function) => (
  <div>
    <span>
      {`${t(
        'The first ERS, Onsite, or ERS & Onsite billing profile which is created is automatically set as the default for all locations.  For default billing profiles, the following fields cannot be modified',
      )}: `}
    </span>
    <br />
    <span style={{ fontWeight: 'bold' }}>{t('Default')}</span>
    <br />
    <span style={{ fontWeight: 'bold' }}>{t('Assign to all locations')}</span>
    <br />
  </div>
);

export const checkGeneral = (
  generalInformation: BillingGeneralInformation,
  locations: BillingProfileLocation[],
  validationErrors: ValidationErrors,
  t: Function,
) => {
  let errorMessage;
  if (!generalInformation.profile_name) {
    errorMessage = t('Profile name must not be blank');
    // eslint-disable-next-line no-param-reassign
    validationErrors.gi_profile_name = true;
  }
  if ((!locations || locations.length < 1) && generalInformation.set_all_locations !== true) {
    errorMessage = t('Profile must be assigned to at least 1 location');
  }
  if (errorMessage) {
    return { errorMessage };
  }
  return false;
};

export default function GeneralInformation(props: GeneralInformationProps) {
  const {
    profile,
    onCallFlag,
    locations,
    editFlag,
    editProfile,
    setModifiedFlag,
    validationErrors,
    editLocations,
    loadingLocations,
    profileTypeHandler,
    isUrbanCustomer,
  } = props;

  const [profileTypeOptions, setProfileTypeOptions] = useState<SelectOption[]>([]);

  /* OPTIONS */
  const { t } = useTranslation();
  const serviceTypes: SelectOption[] = getOptions('billing_service_types');
  const profileTypes: SelectOption[] = getOptions('profile_types');

  const setTheProfileTypeOptions = () => {
    setProfileTypeOptions(
      profileTypes
        .filter((x: SelectOption) => {
          if (onCallFlag) {
            return x.value !== BillingProfileTypes.onsite;
          }

          if (profile.general_information.service_type === BillingServiceTypes.MECHANICAL) {
            return x.value === BillingProfileTypes.onsite;
          }

          if (getListProfileType() === 'onsite') {
            return x.value !== BillingProfileTypes.ers;
          }
          return true;
        })
        .map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        )) as any,
    );
  };

  const serviceTypeOptions = serviceTypes
    .filter((x: SelectOption) => {
      if (onCallFlag) {
        return x.value !== BillingServiceTypes.MECHANICAL;
      }
      return true;
    })
    .map((option) => (
      <MenuItem key={option.value} value={option.value}>
        {option.label}
      </MenuItem>
    ));

  useEffect(() => {
    setTheProfileTypeOptions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // /* DIALOGS */
  let mappedLocations = transformBillingLocationToAsignee(locations);
  let forceAssignmentsViewMode = false;

  /* HANDLERS */
  const handleLocationsUpdate = (currentAssignments: IAssigneeCustomerType[]) => {
    const assignmentsToBillingProfileLocations = transformAsigneeToBillingLocation(currentAssignments);
    editLocations(assignmentsToBillingProfileLocations);
    setModifiedFlag(true);
  };

  const profileNameHandler = (event: ChangeEvent<any>) => {
    const newProfile = { ...profile };
    newProfile.general_information.profile_name = event.target.value;
    validationErrors.gi_profile_name = !newProfile.general_information.profile_name;
    setModifiedFlag(true);
    editProfile(newProfile);
  };

  const serviceTypeHandler = (event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
    const newProfile = { ...profile };
    let onChangeValue = BillingServiceTypes.MECHANICAL;
    switch (event.target.value) {
      case 'tire_wheel':
        onChangeValue = BillingServiceTypes.TIRE_WHEEL;
        newProfile.general_information.service_type = onChangeValue;
        newProfile.general_information.profile_type = BillingProfileTypes.ers_onsite;
        break;
      case 'mechanical':
        onChangeValue = BillingServiceTypes.MECHANICAL;
        newProfile.general_information.service_type = onChangeValue;
        newProfile.general_information.profile_type = BillingProfileTypes.onsite;
        // eslint-disable-next-line no-param-reassign
        event.target.value = 'onsite';
        profileTypeHandler(event);
        break;
      default:
        break;
    }
    setTheProfileTypeOptions();
    setModifiedFlag(true);
    editProfile(newProfile);
  };

  const driverSignatureRequiredHandler = (event: ChangeEvent<any>) => {
    const newProfile = { ...profile };
    newProfile.general_information.driver_signature_required = event.target.checked;
    setModifiedFlag(true);
    editProfile(newProfile);
  };

  const driverNameRequiredHandler = (event: ChangeEvent<any>) => {
    const newProfile = { ...profile };
    newProfile.general_information.driver_name_required = event.target.checked;
    setModifiedFlag(true);
    editProfile(newProfile);
  };

  const owner = profile.owner ? profile.owner.customer_number : auth.getCustomerNumber();
  const isShipTo = (profile.owner ? profile.owner.customer_type : auth.getAccountTypeShort()) === 'ST';

  return (
    <Panel title={t('General Info')} style={{ marginBottom: 15 }}>
      {loadingLocations ? (
        <LoadingBounce style={{ height: 265 }} />
      ) : (
        <>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <Select
                label={t('Service Type')}
                value={profile.general_information.service_type}
                onChange={(event) => serviceTypeHandler(event)}
                required
                style={{ width: '100%' }}
                readOnly={!editFlag}
              >
                {serviceTypeOptions}
              </Select>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Select
                label={t('Profile Type')}
                value={profile.general_information.profile_type}
                onChange={(event) => profileTypeHandler(event)}
                required
                style={{ width: '100%' }}
                readOnly={!editFlag || isUrbanCustomer}
              >
                {profileTypeOptions}
              </Select>
            </Grid>
            <Grid item xs={12} sm={!isShipTo ? 6 : 12}>
              <TextField
                label={t('Billing Profile Name')}
                required
                error={validationErrors.gi_profile_name}
                value={profile.general_information.profile_name}
                style={{ width: '100%' }}
                onChange={profileNameHandler}
                readOnly={!editFlag}
              />
            </Grid>

            {!isShipTo ? (
              <Grid item xs={12} sm={6}>
                <Grid container alignItems="center" style={{ paddingTop: 16 }}>
                  <Grid item xs={9} style={{ textAlign: 'left' }}>
                    <Switch
                      checked={profile.general_information.set_all_locations}
                      name="main.assign_to_all_locations"
                      readOnly
                      label={t('Assign to all locations')}
                      labelPosition="left"
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <LocationAssignments
                      owner={owner}
                      mode={
                        !forceAssignmentsViewMode &&
                        !isShipTo &&
                        editFlag &&
                        !profile.general_information.set_all_locations
                          ? 'edit'
                          : 'view'
                      }
                      locations={mappedLocations}
                      all={profile.general_information.set_all_locations || false}
                      onUpdate={handleLocationsUpdate}
                      style={{ margin: '0 0 0 auto' }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            ) : null}
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={10} lg={6}>
              <Switch
                checked={profile.general_information.default}
                readOnly
                label={t('Default?')}
                labelPosition="left"
              />
            </Grid>
            <Grid item xs={10} lg={6}>
              <Switch
                value={profile.general_information.participates_in_approve_orders}
                checked={profile.general_information.participates_in_approve_orders}
                label={t('Participates in Approve Orders Program?')}
                readOnly
              />
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={10} lg={6}>
              <Switch
                readOnly={!editFlag}
                checked={profile.general_information.driver_signature_required}
                label={t('Driver Signature Required?')}
                onChange={driverSignatureRequiredHandler}
              />
            </Grid>

            <Grid item xs={10} lg={6}>
              <Switch
                checked={profile.general_information.driver_name_required}
                label={t('Driver Name Required?')}
                readOnly={!editFlag}
                onChange={driverNameRequiredHandler}
              />
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={10} lg={6}>
              <Switch
                label={t('PO Required for Invoicing?')}
                readOnly
                checked={profile.general_information.po_required_for_service}
                value={profile.general_information.po_required_for_service}
              />
            </Grid>
          </Grid>
        </>
      )}
    </Panel>
  );
}
