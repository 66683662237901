import React, { useState } from 'react';
import { Grid } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { Button, LoadingBounce, Panel, Switch, TextField, useDialogs } from '@michelin/acid-components';
import { useTranslation } from '@michelin/central-provider';
import { getOptionLabel } from '@michelin/select-options-provider';
import { ClosableDialogTitle } from 'components/ClosableDialog/ClosableDialogTitle';
import { Controller, useForm } from 'react-hook-form';
import { useUpdateData } from '../hooks/useBillingRequirements';
import { IBillingRequirement, IRequirementsDetailProps } from './interfaces';

export default function RequirementsDetail(props: IRequirementsDetailProps) {
  const { t } = useTranslation();
  const { errorDialog, confirmDialog } = useDialogs();
  const data: IBillingRequirement | undefined = { ...props.data };
  const [saving, setSaving] = useState<boolean>(false);
  const { handleSubmit, control, formState } = useForm<{ notesToDealers: string }>({
    defaultValues: {
      notesToDealers: props.data?.notes_to_dealers || '',
    },
  });
  const { isDirty } = formState;
  const updateData = useUpdateData({ fleet: props.costumerNumber });
  const title: string =
    props.dialogTypeDisplayed === 'edit' ? t('Edit Billing Requirement') : t('View Billing Requirement');
  let editMask = `${data?.mask}`;
  if (data?.name === 'po' && data?.alternate_mask) {
    editMask = `${data?.mask} / ${data?.alternate_mask}`;
  }
  /* *********************** */
  /* Actions and Functions   */
  /* *********************** */

  const saveNotesToDealers = async (dataForm: { notesToDealers: string }) => {
    setSaving(true);
    const newBillingNote = { [`${props.data?.name}`]: dataForm.notesToDealers };
    try {
      const response = await updateData.mutateAsync({ fleet: props.costumerNumber, note: newBillingNote });
      setSaving(false);
      if (response?.saved === true) {
        props.onClose();
        props.onUpdate();
      } else {
        errorDialog(t('Please, try again.').split('\n'), t('Error saving Note to Service Provider.'));
      }
    } catch (e) {
      setSaving(false);
      errorDialog(
        t('Please check your connection and try again.\nIf the issue persist, please contact support').split('\n'),
        t('Error saving Note to Service Provider.'),
      );
    }
  };
  const onError = (errors: any) => {
    if (errors.notesToDealers) {
      errorDialog(t('Note to dealers too long.').split('\n'), t('Error checking Notes to Service Provider.'));
    }
  };

  const cancelEdition = () => {
    if (isDirty) {
      confirmDialog(
        t('Are you sure you want to discard all the changes you have made?'),
        t('Discard Changes'),
        t('Yes'),
        t('No'),
        () => {
          props.onClose();
        },
        () => {},
      );
    } else {
      props.onClose();
    }
  };
  const checkNotes = (notes: string): boolean => {
    if (notes.length > 240) {
      return false;
    }
    return true;
  };
  /* ************************* */
  /* Render RequirementsDetail */
  /* ************************* */

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      onClose={() => cancelEdition()}
      aria-labelledby="customized-dialog-title"
      disableEscapeKeyDown
      open
    >
      <ClosableDialogTitle onClose={() => cancelEdition()}>{title}</ClosableDialogTitle>
      <DialogContent id="dialog-content" dividers style={{ padding: 0 }}>
        <Panel variant="none" style={{ border: 0 }} spacing={3}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                id="requirement"
                type="string"
                label={t('Billing Requirement')}
                defaultValue={getOptionLabel('billing_requirements_names', data?.name || '')}
                variant="standard"
                readOnly
              />
            </Grid>
            {props.selectedServiceOffer === 'oncall' ? (
              <Grid item xs={12} sm={6}>
                <Switch
                  id="requiredInvoice"
                  checked={data?.required_on_invoice || false}
                  name="invoice"
                  label={t('Required on Invoice')}
                  labelPosition="left"
                  readOnly
                />
              </Grid>
            ) : (
              <Grid item spacing={2} container>
                <Grid item xs={12} sm={6}>
                  <Switch
                    id="stockingOrder"
                    checked={data?.stockable || false}
                    name="stock"
                    label={t('Stocking Order')}
                    labelPosition="left"
                    readOnly
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Switch
                    id="NonStockingOrder"
                    checked={data?.non_stockable || false}
                    name="NonStock"
                    label={t('Non-Stocking Order')}
                    labelPosition="left"
                    readOnly
                  />
                </Grid>
              </Grid>
            )}
            <Grid item xs={12}>
              <TextField
                id="editMask"
                type="string"
                label={`${t('Edit Mask')} / ${t('Alternate Edit Mask')}`}
                defaultValue={editMask || ''}
                variant="standard"
                readOnly
                multiline
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="Comments"
                type="string"
                label={t('Comments')}
                defaultValue={data?.comments || ''}
                variant="standard"
                readOnly
                multiline
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="notesToDealers"
                control={control}
                rules={{
                  validate: {
                    format: checkNotes,
                  },
                }}
                render={(item) => (
                  <TextField
                    variant="standard"
                    readOnly={props.dialogTypeDisplayed === 'view'}
                    style={{ display: 'flex', flexWrap: 'wrap' }}
                    label={t('Notes to Service Provider')}
                    value={item.field.value}
                    onChange={(e) => item.field.onChange(e.target.value)}
                    placeholder={t('Notes to Service Provider')}
                    error={!!item.formState.errors.notesToDealers}
                    multiline
                    maxLength={240}
                    disabled={saving}
                  />
                )}
              />
            </Grid>
          </Grid>
        </Panel>
      </DialogContent>
      <DialogActions>
        {saving ? (
          <LoadingBounce />
        ) : (
          <>
            {props.dialogTypeDisplayed === 'edit' && (
              <Button
                size="small"
                color="success"
                style={{ margin: '8px' }}
                disabled={!isDirty || saving}
                onClick={handleSubmit(saveNotesToDealers, onError)}
              >
                {t('Update')}
              </Button>
            )}
            <Button size="small" color="default" style={{ margin: '8px' }} disabled={saving} onClick={cancelEdition}>
              {t('Close')}
            </Button>
          </>
        )}
      </DialogActions>
    </Dialog>
  );
}
