import React, { useEffect, useState } from 'react';
import { CentralProviderSidebar, usePermissions, useTranslation } from '@michelin/central-provider';
import { runningOnCanvas } from '@michelin/central-provider/dist/utils/canvas';
import { auth } from 'Auth';
import { getVersion } from 'components/Util/version';
import pns, {
  PreferenceType,
  ServiceOfferIds,
  buildPreferences,
  getSelectedServiceOffer,
} from 'prefs-and-service-offers';
import { useHistory, useLocation } from 'react-router-dom';

function Icon(props: { file: string }) {
  if (!props.file) return null;
  const url = `${process.env.PUBLIC_URL}/static/images/preferencesIcons/${props.file}.svg`;
  return <img src={url} alt={props.file} style={{ width: 26, marginRight: 5, marginLeft: -3 }} />;
}

export default () => {
  const { t } = useTranslation();
  const [selectedModule, setSelectedModule] = useState<string>();
  const [frontendVersion, setFrontendVersion] = useState<string>('');
  const [backendVersion, setBackendVersion] = useState<string>('');
  const permissions = usePermissions();
  const history = useHistory();

  const [openServices, setOpenServices] = useState<boolean[]>([]);
  const toggleOpenSercice = (index: number) => {
    const newOpenServices = [...openServices];
    newOpenServices[index] = !newOpenServices[index];
    setOpenServices(newOpenServices);
  };
  const urbanOnlyFleet: boolean = !!(
    permissions.location &&
    permissions.location.is_urban &&
    !permissions.location.is_commercial
  );
  const allPreferences = {
    'all-preferences': buildPreferences(auth, 'allPreferences'),
    'oncall': buildPreferences(auth, 'onCallPreferences'),
    'bulk-order': buildPreferences(auth, 'bulkOrderPreferences'),
  };

  useEffect(() => {
    setFrontendVersion(getVersion());
    setBackendVersion(auth.getApiVersion() || '');
  }, []);

  const location = useLocation();

  useEffect(() => {
    const [, , , mod] = location.pathname.split('/');
    if (mod.includes('location')) setSelectedModule(PreferenceType.locations);
    if (mod.includes('contact')) setSelectedModule(PreferenceType.contacts);
    if (mod.includes('account')) setSelectedModule(PreferenceType.accounts);
    if (mod.includes('billing')) setSelectedModule(PreferenceType.billing);
    if (mod.includes('tire-profile')) setSelectedModule(PreferenceType.tires);
    if (mod.includes('wheel')) setSelectedModule(PreferenceType.wheels);
    if (mod.includes('mechanical')) setSelectedModule(PreferenceType.mechanical);
    if (mod.includes('sop')) setSelectedModule(PreferenceType.sop);
    if (mod.includes('sop')) setSelectedModule(PreferenceType.sopRefactor);
    if (mod.includes('vehicle')) setSelectedModule(PreferenceType.vehicles);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  const items: CentralProviderSidebar.Item[] = [];
  const selectedServiceOffer = getSelectedServiceOffer();

  pns.GetServiceOffers().forEach((serviceOffer, i) => {
    if (urbanOnlyFleet && serviceOffer.id !== ServiceOfferIds.bulkOrder) return;
    if (auth.getServiceOffersSubscriptions().indexOf(serviceOffer.id) === -1) return;
    const selected = selectedServiceOffer && serviceOffer.id === selectedServiceOffer.id;
    if (openServices[i] === undefined && selected) toggleOpenSercice(i);
    items.push({
      label: t(serviceOffer.shortTitle),
      icon: <Icon file={serviceOffer.icon} />,
      open: !!openServices[i],
      selected,
      onClick: () => toggleOpenSercice(i),
      subitems: allPreferences[serviceOffer.id].map((pref) => ({
        label: t(pref.title),
        icon: <Icon file={pref.icon} />,
        selected: selected && selectedModule === pref.preference,
        onClick: () => history.push(`/${permissions.location?.customer_number}${pref.path}`),
      })),
    });
  });

  const versions = [
    {
      application: t('UI'),
      version: frontendVersion,
    },
    {
      application: t('API'),
      version: backendVersion,
    },
  ];

  return (
    <CentralProviderSidebar
      versions={versions}
      items={items}
      showCopyright={!runningOnCanvas()}
      showLegalInformation={false}
      showApplicationSelector={!runningOnCanvas()}
    />
  );
};
