import React, { useEffect, useRef, useState } from 'react';
import { IconButton, Link, Popover, makeStyles } from '@material-ui/core';
import { Dialog, DialogContent } from '@material-ui/core';
import { Delete, Visibility } from '@material-ui/icons';
import { MechanicalProfileOVM } from '@michelin/fcp-view-models';
import { getOptionLabel } from '@michelin/select-options-provider';
import { ClosableDialogTitle } from 'components/ClosableDialog/ClosableDialogTitle';
import CreatedByAvatar from 'components/CreatedByAvatar';
import { getBaseUrl } from 'prefs-and-service-offers';
import { useHistory } from 'react-router-dom';
import { extractFirstTextFromHtml } from 'utils/reusableMethods';

export interface MechanicalProfileRow {
  data: MechanicalProfileOVM;
}

export const ProfileNameCell = ({ row }: { row: MechanicalProfileRow }) => {
  const history = useHistory();
  if (!row || !row.data || !row.data.main?.profile_name || !row.data.id) return <></>;

  return (
    <>
      <Link
        onClick={() => history.push(`${getBaseUrl()}/mechanical-profile/${row.data.id}/view`)}
        style={{ cursor: 'pointer' }}
      >
        {row.data.main.profile_name}
      </Link>
    </>
  );
};

export const CreatedByCell = (row: MechanicalProfileRow) => {
  if (!row || !row.data || !row.data.owner) return null;
  return (
    <CreatedByAvatar customerType={row.data.owner.customer_type} customerNumber={row.data.owner.customer_number} />
  );
};

export const OptionCell = ({ option, value }: any) => {
  return <span>{getOptionLabel(option, value)}</span>;
};

export const RenderSpecialInstructionsCell = ({ htmlString }: { htmlString: string }) => {
  if (!htmlString) return <></>;
  const firstText = extractFirstTextFromHtml(htmlString);
  if (!firstText) return <></>;

  return <ShowSpecialInstructionsPopover htmlString={htmlString} firstText={firstText} />;
};

const useStyles = makeStyles((theme: any) => ({
  rightAlign: {
    textAlign: 'right',
  },
  marginTop: {
    marginTop: '13px',
  },
  popover: {
    pointerEvents: 'none',
  },
  paper: {
    padding: theme.spacing(1),
  },
  margin: {
    margin: 2,
  },
}));

const truncateText = (text: string, maxLength: number) => {
  return (text.length > maxLength ? text.substring(0, maxLength) : text) + '...';
};

const ShowSpecialInstructionsPopover = ({ htmlString, firstText }: { htmlString: string; firstText: string }) => {
  const cls = useStyles();

  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const popoverRef = useRef<HTMLDivElement | null>(null);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (popoverRef.current && !popoverRef.current.contains(event.target as Node)) {
        handlePopoverClose();
      }
    };
    if (open) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [open]);

  return (
    <>
      <Link
        aria-label="tire-sizes"
        className={cls.margin}
        style={{ cursor: 'pointer', textDecoration: 'none' }}
        color="primary"
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
        aria-owns={open ? 'mouse-over-popover' : undefined}
        aria-haspopup="true"
      >
        {truncateText(firstText, 30)}
        <Popover
          id="mouse-over-popover"
          className={cls.popover}
          classes={{
            paper: cls.paper,
          }}
          open={open}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          ref={popoverRef}
        >
          <div
            style={{
              maxWidth: '600px',
              margin: '10px',
              wordWrap: 'break-word',
              whiteSpace: 'pre-wrap',
              textAlign: 'justify',
            }}
            dangerouslySetInnerHTML={{ __html: htmlString }}
          />
        </Popover>
      </Link>
    </>
  );
};

interface SpecialInstructionsInterface {
  open: boolean;
  title: string;
  htmlString: string;
  onCloseModal: () => void;
}

export function SpecialInstructionsDialog(props: SpecialInstructionsInterface) {
  const { open, title, htmlString, onCloseModal } = props;
  return (
    <Dialog
      onClose={() => onCloseModal()}
      aria-labelledby="customized-dialog-title"
      open={open}
      fullWidth
      maxWidth="sm"
    >
      <ClosableDialogTitle onClose={() => onCloseModal()}>{title}</ClosableDialogTitle>
      <DialogContent dividers>
        <div
          style={{
            wordWrap: 'break-word',
            whiteSpace: 'pre-wrap',
            textAlign: 'justify',
          }}
          dangerouslySetInnerHTML={{ __html: htmlString }}
        />
      </DialogContent>
    </Dialog>
  );
}

export function ActionsCell({ row, confirmDeleteProfile }: { row: MechanicalProfileOVM; confirmDeleteProfile: any }) {
  const htmlStringRow = row.main?.special_instructions;
  const canDelete = row.allowedActions?.delete;
  const canView = htmlStringRow && extractFirstTextFromHtml(htmlStringRow);
  const [openSpecialInstructionsDialog, setOpenSpecialInstructionsDialog] = useState(false);

  return (
    <div>
      {openSpecialInstructionsDialog && (
        <SpecialInstructionsDialog
          open={openSpecialInstructionsDialog}
          htmlString={row.main?.special_instructions || ''}
          onCloseModal={() => {
            setOpenSpecialInstructionsDialog(false);
          }}
          title={row.main?.profile_name || ''}
        />
      )}
      <IconButton
        aria-label="edit"
        style={{ margin: '8px' }}
        onClick={() => {
          setOpenSpecialInstructionsDialog(true);
        }}
        size="small"
        disabled={!canView}
      >
        <Visibility fontSize="small" color={!canView ? 'disabled' : 'primary'} />
      </IconButton>

      {canDelete ? (
        <IconButton
          aria-label="delete"
          style={{ margin: '8px' }}
          onClick={async () => {
            confirmDeleteProfile(row);
          }}
          size="small"
        >
          <Delete fontSize="small" color={'primary'} />
        </IconButton>
      ) : null}
    </div>
  );
}
