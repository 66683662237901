import { MaestroAPI, maestroApiCall } from '@michelin/central-provider';
import { MethodType } from '@michelin/central-provider/dist/helpers/createAPICall';
import { MechanicalProfileOVM } from '@michelin/fcp-view-models';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import GLOBAL_CONFIG from 'global-variables';
import { Actions } from 'modules/Mechanical/types';

const baseURL = GLOBAL_CONFIG.externalAPIUrl;

interface mechanicalProfileDeleteResult {
  hash_key: string;
  range_key: string;
  is_deleted: boolean;
}

const deleteMechanicalProfile = async ({
  fleetId,
  mechanicalId,
}: {
  fleetId: string;
  mechanicalId: string;
}): Promise<mechanicalProfileDeleteResult | null> => {
  if (!fleetId || !mechanicalId) return null;
  const apiType: MaestroAPI = {
    method: 'DELETE',
    endpoint: `cp-be/mechanicals/${fleetId}/${mechanicalId}`,
    baseURL,
  };
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  return maestroApiCall(apiType);
};
export function useDeleteMechanicalProfile() {
  return useMutation(deleteMechanicalProfile);
}

async function getMechanicalProfile(profileId: string, accountNumber: string, action: Actions) {
  if (action === 'create')
    return {
      id: '',
      owner: {},
      main: {
        profile_type: '',
        profile_name: '',
        application_type: '',
        driver_can_override: false,
        special_instructions: '<p><br></p>',
        allowed_to_call_in_requests: [],
      },
    };

  if (!profileId || !accountNumber) return null;

  const data = await maestroApiCall({
    method: 'GET',
    endpoint: `cp-be/mechanicals/${accountNumber}/${profileId}`,
    baseURL,
  });
  return data;
}

export function useGetMechanicalProfile({
  profileId,
  accountNumber,
  action,
}: {
  profileId: string;
  accountNumber: string;
  action: Actions;
}) {
  return useQuery<MechanicalProfileOVM>(['mechanical_profile', profileId, accountNumber], () =>
    getMechanicalProfile(profileId, accountNumber, action),
  );
}

async function saveMechanicalProfile({
  accountNumber,
  data,
  action,
}: {
  accountNumber: string;
  data: MechanicalProfileOVM;
  action: string;
}) {
  const endpoint = `cp-be/mechanicals/${accountNumber}`;

  const apiType: MaestroAPI = {
    method: action === 'edit' ? MethodType.PUT : MethodType.POST,
    endpoint: action === 'edit' ? `${endpoint}/${data.id}` : endpoint,
    baseURL,
  };
  const newData: MechanicalProfileOVM = {
    ...data,
    ...{ main: { ...data.main, profile_name: data.main?.profile_name?.trim() } },
  };

  const response = maestroApiCall<MechanicalProfileOVM>(apiType, newData);
  return response;
}
export function useSaveMechanicalProfile({
  accountNumber,
  profileId,
}: {
  accountNumber: string;
  profileId: string | undefined;
}) {
  const queryClient = useQueryClient();
  return useMutation(saveMechanicalProfile, {
    onSuccess: () => {
      queryClient.invalidateQueries(['mechanical_profile', profileId, accountNumber]);
    },
  });
}
