import React, { useEffect, useState } from 'react';
import { Button, Grid, IconButton, makeStyles } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { TextField } from '@michelin/acid-components';
import { useTranslation } from '@michelin/central-provider';
import { Controller, useFieldArray, useForm } from 'react-hook-form';

interface PlatformsListProps {
  defaultValues: string[];
  onValuesChange: (values: string[]) => void;
  readOnly?: boolean;
  error?: boolean;
}

const useStyles = makeStyles((theme) => ({
  smallButton: {
    padding: theme.spacing(0.5, 1),
    fontSize: '0.85rem',
  },
}));

export const PlatformsList: React.FC<PlatformsListProps> = ({ defaultValues, onValuesChange, readOnly, error }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { control, watch, reset } = useForm({
    defaultValues: {
      platformsList: defaultValues.map((value) => ({ value })),
    },
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'platformsList',
  });

  const watchFields = watch('platformsList');
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    if (isLoading) {
      const timer = setTimeout(() => {
        reset({
          platformsList: defaultValues.map((value) => ({ value })),
        });
        setIsLoading(false);
      }, 500);
      return () => clearTimeout(timer);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValues]);

  useEffect(() => {
    const subscription = watch((value) => {
      const values = (value.platformsList ?? []).map((field: any) => field.value);
      onValuesChange(values);
    });
    return () => subscription.unsubscribe();
  }, [watch, onValuesChange]);

  const isAnyFieldEmpty = watchFields.some((field: any) => !field.value);

  if (isLoading) {
    return <></>;
  }

  return (
    <Grid container spacing={2}>
      {fields.map((field, index) => (
        <Grid item xs={12} key={field.id} container alignItems="center">
          <Grid item xs={11}>
            <Controller
              name={`platformsList.${index}.value`}
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label={`${t('Platform')} ${index + 1}`}
                  variant="standard"
                  fullWidth
                  readOnly={readOnly}
                  required={index === 0}
                  error={index === 0 && error}
                  helperText={index === 0 && error ? t('At least a platform is required').toString() : ''}
                  maxLength={250}
                />
              )}
            />
          </Grid>
          {!readOnly && (
            <Grid item xs={1}>
              <IconButton color="primary" onClick={() => remove(index)} disabled={fields.length === 1 || readOnly}>
                <DeleteIcon />
              </IconButton>
            </Grid>
          )}
        </Grid>
      ))}
      {!readOnly && (
        <Grid item xs={12}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => append({ value: '' })}
            disabled={isAnyFieldEmpty || readOnly}
            className={classes.smallButton}
          >
            {t('Add Platform')}
          </Button>
        </Grid>
      )}
    </Grid>
  );
};
