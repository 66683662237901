import { MaestroAPI } from '@michelin/central-provider';
import { auth } from 'Auth';
import GLOBAL_CONFIG from '../../global-variables';

const baseURL = GLOBAL_CONFIG.externalAPIUrl;
export interface APITypes {
  method: 'GET' | 'PUT' | 'POST' | 'DELETE';
  endpoint: string | null;
}

export function vehicleSaveAPIType(fleet_id: string, vehicle_id: string): APITypes {
  const method: APITypes['method'] = vehicle_id ? 'PUT' : 'POST';
  const endpoint: string = vehicle_id ? `cp-be/vehicles/${fleet_id}/${vehicle_id}` : `cp-be/vehicles/${fleet_id}`;
  return { method, endpoint };
}

export function urbanVehiclesAPIType(fleet_id: string, vehicle_id: string): APITypes {
  const method: APITypes['method'] = 'PUT';
  const endpoint: string = `cp-be/vehicles/${fleet_id}/${vehicle_id}`;
  return { method, endpoint };
}

export function vehiclesDetailAPIType(fleet_id: string, vehicle_id: string): APITypes {
  const fleetId = fleet_id;
  return { method: 'GET', endpoint: fleetId && vehicle_id ? `cp-be/vehicles/${fleetId}/${vehicle_id}` : null };
}

export function vehiclesDeleteAPIType(fleet_id: string, vehicle_id: string | number): APITypes {
  return { method: 'DELETE', endpoint: fleet_id && vehicle_id ? `cp-be/vehicles/${fleet_id}/${vehicle_id}` : null };
}

export function vehiclesListingAPIType(fleet_id: string): MaestroAPI {
  return { baseURL, method: 'GET', endpoint: `cp-be/vehicles/${fleet_id}` };
}

export function yearsAPIType(vehicle_type: string): APITypes {
  return { method: 'GET', endpoint: `cp-be/ymmos/years?type=${vehicle_type}` };
}

export function makesAPIType(vehicle_type: string): APITypes {
  return { method: 'GET', endpoint: `cp-be/ymmos/makes?type=${vehicle_type}` };
}

export function modelsAPIType(vehicle_type: string): APITypes {
  return { method: 'GET', endpoint: `cp-be/ymmos/models?type=${vehicle_type}` };
}

export function optionsAPIType(vehicle_type: string): APITypes {
  return { method: 'GET', endpoint: `cp-be/ymmos/options?type=${vehicle_type}` };
}

export function tireSizesAPIType(vehicle_type: string): APITypes {
  return { method: 'GET', endpoint: `cp-be/ymmos/sizes?type=${vehicle_type}` };
}

export function customTireSizeAPIType(): APITypes {
  return { method: 'GET', endpoint: 'cp-be/custom-tire-size/custom' };
}

export function tireProfileDeleteAPIType(fleet_id: string, tire_id: string): APITypes {
  return { method: 'DELETE', endpoint: fleet_id && tire_id ? `cp-be/tires/${fleet_id}/${tire_id}` : null };
}

export function sopList(fleet_id: string): MaestroAPI {
  const endpoint = `cp-be/sop/${fleet_id}`;
  return { method: 'GET', endpoint, baseURL };
}

export function sopDelete(fleet_id: string, profile_id: string): MaestroAPI {
  const endpoint = `cp-be/sop/${fleet_id}/${profile_id}`;
  return { method: 'DELETE', endpoint, baseURL };
}

export function sopValidationTireProfileDelete(fleet_id: string, profile_id: string): MaestroAPI {
  const endpoint = `cp-be/tires/${fleet_id}/${profile_id}/validate_removal/sop`;
  return { method: 'GET', endpoint, baseURL };
}

export function sopValidationTireAxleDelete(fleet_id: string, profile_id: string, axles?: string[]): MaestroAPI {
  const endpoint = `cp-be/tires/${fleet_id}/${profile_id}/validate_removal/axle?axle_types=${axles?.join(',')}`;
  return { method: 'GET', endpoint, baseURL };
}

export function sopValidationTireSizeDelete(
  fleet_id: string,
  profile_id: string,
  axles?: string[],
  tire_size?: string,
): MaestroAPI {
  const endpoint = `cp-be/tires/${fleet_id}/${profile_id}/validate_removal/size?axle_type=${
    axles && axles[0]
  }&tire_size=${tire_size}`;
  return { method: 'GET', endpoint, baseURL };
}

export function profileList(fleet_id: string): MaestroAPI {
  const endpoint = `cp-be/billing/${fleet_id}/profiles`;
  return { method: 'GET', endpoint, baseURL };
}

export function billingDrNotesList(fleet_id: string): MaestroAPI {
  const endpoint = `cp-be/billing/${fleet_id}/dr-notes`;
  return { method: 'GET', endpoint, baseURL };
}

export function billingRequirementsAPIType(fleet_id: string, prefType: string): MaestroAPI {
  let endpoint: string = `cp-be/billing/${fleet_id}/requirements/all`;
  if (prefType === 'oncall') {
    endpoint = `cp-be/billing/${fleet_id}/requirements/oncall`;
  } else if (prefType === 'bulk-order') {
    endpoint = `cp-be/billing/${fleet_id}/requirements/fleetorder`;
  }
  return { method: 'GET', baseURL, endpoint };
}

// CHANGELOGS --------------------------------------------------------
export function accountChangelogAPIType(): MaestroAPI {
  const method: APITypes['method'] = 'GET';
  const endpoint = `cp-be/accounts/${auth.getCustomerNumber()}/changelogs/${auth.getRelationship()}/`;
  return { method, baseURL, endpoint };
}
