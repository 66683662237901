import React, { useState } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, Link, useMediaQuery, useTheme } from '@material-ui/core';
import { Edit } from '@material-ui/icons';
import { Button, IconButton } from '@michelin/acid-components';
import {
  clearMaestroDataSourceCache,
  launchAccountSelector,
  useMaestroDataSource,
  useTranslation,
} from '@michelin/central-provider';
import { getOptionLabel } from '@michelin/select-options-provider';
import { auth } from 'Auth';
import { billingRequirementsAPIType } from 'components/Types/APITypes';
import { TABBED_CONTENT_HEIGHT } from 'components/Util';
import DataGrid, { Column, MasterDetail, Scrolling } from 'devextreme-react/data-grid';
import { getSelectedServiceOffer } from 'prefs-and-service-offers';
import RenderCellView from './DataCellView';
import RequirementsDetail from './RequirementsDetail';
import { IBillingRequirement } from './interfaces';
import './requirements.css';

export default function BillingRequirements() {
  const [notCorrectAccountType, setNotCorrectAccountType] = useState(
    auth.getAccountType() === 'homeOffice' || auth.getAccountType() === 'parentCompany',
  );
  const [correctAccountType] = useState(auth.getAccountType() === 'billTo' || auth.getAccountType() === 'shipTo');
  const [costumerNumber] = useState<string>(auth.getCustomerNumber());
  const [selectedServiceOffer] = useState<string>(getSelectedServiceOffer().id);
  const { t } = useTranslation();
  const theme = useTheme();
  const cellView = useMediaQuery(theme.breakpoints.down('xs'));

  function RequirementsList() {
    const [displayDialog, setDisplayDialog] = useState<'edit' | 'view' | null>(null);
    const [detailData, setDetailData] = useState<IBillingRequirement | undefined>(undefined);
    const ds = useMaestroDataSource({
      API: billingRequirementsAPIType(costumerNumber, selectedServiceOffer),
      cacheKey: `billings-${costumerNumber}-${selectedServiceOffer}`,
    });
    const [rowExpanded, setRowExpanded] = useState<string | null>(null);
    const onClose = () => {
      setDetailData(undefined);
      setDisplayDialog(null);
    };
    const onUpdate = () => {
      async function reload() {
        clearMaestroDataSourceCache(`billings-${costumerNumber}-${selectedServiceOffer}`);
        await ds.dataSource.reload();
      }
      reload();
    };
    const handleOpenDetail = ({ data, action }: { data: IBillingRequirement; action: 'edit' | 'view' }) => {
      setDisplayDialog(action);
      setDetailData(data);
    };
    function renderEditMask(data: IBillingRequirement): JSX.Element {
      return (
        <>
          {data.name === 'po' && (
            <>
              {data.mask}
              {data.alternate_mask && (
                <>
                  {' / '}
                  {data.alternate_mask}
                </>
              )}
            </>
          )}
          {data.name !== 'po' && <>{data.mask}</>}
        </>
      );
    }
    function renderActionsCell(requirement: IBillingRequirement): JSX.Element {
      const data: IBillingRequirement = { ...requirement };
      return (
        <div>
          <IconButton
            aria-label={t('Edit')}
            style={{ margin: '8px' }}
            onClick={() => {
              handleOpenDetail({ data, action: 'edit' });
            }}
            size="small"
          >
            <Edit fontSize="small" color="primary" />
          </IconButton>
        </div>
      );
    }
    const contentReadyEvent = (e: any) => {
      // eslint-disable-next-line no-underscore-dangle
      const items = e.component.getDataSource()._items;
      items.forEach((element: IBillingRequirement) => {
        if (rowExpanded !== null && element.name === rowExpanded) {
          e.component.expandRow(element);
        }
      });
    };
    const onRowCollapsedEvent = (e: any) => {
      if (e.key.name === rowExpanded) {
        setRowExpanded(null);
      }
    };
    const onRowExpandedEvent = (e: any) => {
      const newRowExpande = e.key.name;
      const oldRowExpande = rowExpanded;
      // eslint-disable-next-line no-underscore-dangle
      const items = e.component.getDataSource()._items;
      setRowExpanded(newRowExpande);
      for (let i = 0; i < items.length; i++) {
        if (items[i].name === oldRowExpande && items[i].name !== newRowExpande) {
          e.component.collapseRow(items[i]);
          break;
        }
      }
    };

    /* *********************** */
    /* Render RequirementsList */
    /* *********************** */
    return (
      <>
        {(displayDialog === 'edit' || displayDialog === 'view') && (
          <RequirementsDetail
            onClose={onClose}
            dialogTypeDisplayed={displayDialog}
            onUpdate={onUpdate}
            data={detailData}
            selectedServiceOffer={selectedServiceOffer}
            costumerNumber={costumerNumber}
          />
        )}
        <DataGrid
          style={{ height: TABBED_CONTENT_HEIGHT, border: 'none' }}
          noDataText={t('No billing requirements available to display.')}
          dataSource={ds.dataSource}
          allowColumnResizing
          wordWrapEnabled
          remoteOperations
          columnAutoWidth
          onContentReady={contentReadyEvent}
          onRowExpanded={onRowExpandedEvent}
          onRowCollapsing={onRowCollapsedEvent}
        >
          <Scrolling mode="virtual" />
          <Column
            caption={!cellView ? t('Billing Requirement') : ''}
            cssClass="dataGrid-max-width-small"
            cellRender={(row) => (
              <Link onClick={() => handleOpenDetail({ data: row.data, action: 'view' })} style={{ cursor: 'pointer' }}>
                {getOptionLabel('billing_requirements_names', row.data.name)}
              </Link>
            )}
          />
          <Column
            cssClass="dataGrid-max-width-mini"
            caption={t('Stocking Order')}
            cellRender={({ row }) => (row.data.stockable ? t('Yes') : '-')}
            visible={selectedServiceOffer !== 'oncall' && !cellView}
          />
          <Column
            cssClass="dataGrid-max-width-mini"
            caption={t('Non-Stocking Order')}
            cellRender={({ row }) => (row.data.non_stockable ? t('Yes') : '-')}
            visible={selectedServiceOffer !== 'oncall' && !cellView}
          />
          <Column
            cssClass="dataGrid-max-width-mini"
            caption={t('Required on Invoice')}
            cellRender={({ row }) => (row.data.required_on_invoice ? t('Yes') : '-')}
            visible={selectedServiceOffer === 'oncall' && !cellView}
          />
          <Column
            caption={`${t('Edit Mask')} / ${t('Alternate Edit Mask')}`}
            cssClass="WrappedColumnClass"
            cellRender={({ row }) => renderEditMask(row.data)}
            visible={!cellView}
          />
          <Column
            caption={t('Comments')}
            cssClass="WrappedColumnClass, dataGrid-min-width"
            cellRender={({ row }) => row.data.comments}
            visible={!cellView}
          />
          <Column
            caption={t('Notes to Service Provider')}
            cssClass="WrappedColumnClass, dataGrid-min-width"
            cellRender={({ row }) => row.data.notes_to_dealers}
            visible={!cellView}
          />
          <Column width={80} cellRender={({ row }) => renderActionsCell(row.data)} />
          <MasterDetail enabled={cellView} component={RenderCellView} />
        </DataGrid>
      </>
    );
  }
  /* ***************************** */
  /* Render billingRequirementName */
  /* ***************************** */
  return (
    <>
      <Dialog open={notCorrectAccountType} maxWidth="md">
        <DialogTitle>{t('Billing requirements are only available for a Bill To or Ship To account.')}</DialogTitle>
        <DialogContent>
          <p>{t('Please, change your account or select the Bill To/Ship To you would like to view.')}</p>
        </DialogContent>
        <DialogActions>
          <Button onClick={launchAccountSelector}>{t('Switch Account')}</Button>
          <Button onClick={() => setNotCorrectAccountType(false)} color="primary">
            {t('Close')}
          </Button>
        </DialogActions>
      </Dialog>
      {correctAccountType ? (
        <RequirementsList />
      ) : (
        <p style={{ margin: '16px' }}>{t('No billing requirements available to display.')}</p>
      )}
    </>
  );
}
