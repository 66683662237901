import { useEffect, useRef } from 'react';
import { SelectOption } from '@michelin/select-options-provider';
import dayjs from 'dayjs';

export const formatDate = (date: string, format?: string) => {
  return dayjs(date).format(format || 'MMMM D, YYYY h:mm A');
};

export const upperText = (text: string) => {
  if (text.length > 0) {
    return text
      .toLowerCase()
      .split(' ')
      .map((s) => s.charAt(0).toUpperCase() + s.slice(1))
      .join(' ');
  }
  return '';
};

export function formatDateShortMonth(strDate: string) {
  if (!strDate) return null;
  return dayjs(strDate).format('MMM D YYYY h:mm A').toString();
}

export const toHoursMin = (totalMin: number | undefined): string | undefined => {
  if (typeof totalMin !== 'number') return 'No hours were received';
  const hours = Math.floor(totalMin / 60);
  const min = Math.floor(totalMin - hours * 60);
  return `${hours} hr, ${min} min`;
};

export const toDaysHoursMin = (totalMin: number | undefined): string | undefined => {
  if (typeof totalMin !== 'number' || totalMin < 0) return 'No hours were received';
  const days = Math.floor(totalMin / 1440);
  const hours = Math.floor((totalMin - days * 1440) / 60);
  const min = Math.floor(totalMin - hours * 60 - days * 1440);
  const daysString = days === 1 ? `${days} day,` : `${days} days,`;
  return days === 0 ? `${hours} hr, ${min} min` : `${daysString} ${hours} hr, ${min} min`;
};

export const toHoursMinShort = (minutes: number): string => {
  return `${Math.floor(minutes / 60)}:${minutes % 60}`;
};

export const snakeToTitleCase = (snakeCase: string) => {
  const titleCase = snakeCase
    .split('_')
    .filter((x) => x.length > 0)
    .map((x) => x.trim().charAt(0).toUpperCase() + x.slice(1).toLowerCase())
    .join(' ');
  return titleCase;
};

export const upperToTitleCase = (upperCase: string) => {
  const titleCase = upperCase
    .split(' ')
    .filter((x) => x.length > 0)
    .map((x) => x.trim().charAt(0).toUpperCase() + x.slice(1).toLowerCase())
    .join(' ');
  return titleCase;
};

export function useDebounce(time: number) {
  const timer = useRef<number | null>(null);
  useEffect(
    () => () => {
      if (timer.current) clearTimeout(timer.current);
    },
    [],
  );
  return (callback: () => void) => {
    if (timer.current) clearTimeout(timer.current);
    timer.current = setTimeout(callback, time);
  };
}

export const getAutocompleteValues = (values: string[] | undefined, options: SelectOption[]): SelectOption[] => {
  if (!values) return [];
  return options.filter((opt) => values.includes(opt.value));
};

export const extractFirstTextFromHtml = (htmlString: string): string => {
  if (!htmlString) return ''
  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlString, 'text/html');
  const walker = document.createTreeWalker(doc.body, NodeFilter.SHOW_TEXT, null);
  let node;
  while ((node = walker.nextNode())) {
    const text = node.nodeValue?.trim();
    if (text) {
      return text;
    }
  }
  return '';
}

export const filterTheAccountTypes = (options: SelectOption[], customerType: string) => {
  let ret: SelectOption[] = [];
  if (customerType) {
    const innerType = customerType;
    ret = options.filter((a: SelectOption) => {
      if (/pc/i.test(innerType)) {
        return true;
      }
      if (/ho/i.test(innerType)) {
        if (/^pc/i.test(a.value)) return false;
      }
      if (/bt/i.test(innerType)) {
        if (/^(pc|ho)/i.test(a.value)) return false;
      }
      if (/st/i.test(innerType)) {
        if (/^(pc|ho|bt)/i.test(a.value)) return false;
      }
      return true;
    });
  }
  ret.sort((a: SelectOption, b: SelectOption) => {
    if (/all/i.test(a.value)) return -5;
    if (/all/i.test(b.value)) return 5;
    if (/^pc/i.test(a.value)) return -4;
    if (/^pc/i.test(b.value)) return 4;
    if (/^ho/i.test(a.value)) return -3;
    if (/^ho/i.test(b.value)) return 3;
    if (/^bt/i.test(a.value)) return -2;
    if (/^bt/i.test(b.value)) return 2;
    if (/^st/i.test(a.value)) return 1;
    if (/^st/i.test(b.value)) return -1;
    return 0;
  });
  return ret;
};