import React, { useEffect, useState } from 'react';
import { Grid, Typography } from '@material-ui/core';
import { Panel, Select, Switch, TextField, Tooltip } from '@michelin/acid-components';
import { usePermissions, useTranslation } from '@michelin/central-provider';
import { TireProfileOVM } from '@michelin/fcp-view-models';
import { getOptionLabel, getOptions } from '@michelin/select-options-provider';
import { NewLocationAssignments } from 'components/NewLocationAssignments';
import { ReactQuillEditor } from 'components/ReactQuillEditor/ReactQuillEditor';
import { Actions, AxleName, Axles } from 'modules/Tires/types';
import { SelectedServiceOffer, ServiceOfferIds, getSelectedServiceOffer } from 'prefs-and-service-offers';
import { Controller, useFormContext } from 'react-hook-form';

interface props {
  action: Actions;
  axles: Axles | null;
}

function filterTrailerOptions() {
  const trailerOptions = getOptions('vehicle_attrs_trailer');
  trailerOptions.splice(0, 0, trailerOptions.splice(1, 1)[0]);
  return trailerOptions;
}

const vehicleTypeVMRS: { code: string; type: 'tractor' | 'trailer' | 'both' }[] = [
  { code: '1', type: 'tractor' },
  { code: '2', type: 'tractor' },
  { code: '3', type: 'trailer' },
  { code: '4', type: 'trailer' },
  { code: '5', type: 'both' },
];

export function MainTab({ action, axles }: props) {
  const { t } = useTranslation();
  const { location } = usePermissions();
  const { control, formState, watch, getValues, setValue, trigger } = useFormContext<TireProfileOVM>();
  // Watch's
  const profileType = watch('main.profile_type');
  const vehicleType = watch('main.vehicle_type_vmrs_code');
  const offTireDisposition = watch('main.off_tire_disposition');

  const locSelectedServiceOffer: SelectedServiceOffer | undefined = getSelectedServiceOffer() || undefined;
  const isTrailerOrTractor = vehicleTypeVMRS.find((v) => v.code === vehicleType || '');
  const [locationsBackup, setLocationsBackup] = useState<string[]>([]);

  const editorConfig = {
    height: 150,
    readOnly: action === 'view',
    isEditing: action !== 'view',
  };

  const [thereIsAxle, setThereIsAxle] = useState<boolean>(false);

  useEffect(() => {
    if (axles) {
      const thereIsAxleWithValueTrue = Object.keys(axles).some((key: string) => axles[key as AxleName].value === true);
      setThereIsAxle(thereIsAxleWithValueTrue);
    }
  }, [axles]);
  useEffect(() => {
    if (action !== 'add') return;
    let profileType: string | undefined;

    switch (locSelectedServiceOffer?.id) {
      case ServiceOfferIds.allPreferences: // All Preferences
        break;
      case ServiceOfferIds.onCall: // OnCall - ERS
        profileType = 'ers';
        break;
      case ServiceOfferIds.bulkOrder: // Fleet Order
        profileType = 'onsite';
        break;
      default:
        break;
    }

    if (profileType) setValue('main.profile_type', profileType, { shouldDirty: true });
    if (location?.customer_type === 'ST')
      setValue('main.assign_to_all_locations', true, { shouldDirty: true, shouldValidate: true });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [action]);

  return (
    <Panel>
      <Grid container spacing={2}>
        <Grid item xs={12} lg={2}>
          <Controller
            name="main.profile_type"
            control={control}
            rules={{ required: { value: true, message: t('Profile Type is required').toString() } }}
            render={({ field, fieldState }) => (
              <Tooltip
                title={t('Profile Type cannot be changed if an axle was selected').toString()}
                disableHoverListener={
                  !thereIsAxle || locSelectedServiceOffer?.id !== ServiceOfferIds.allPreferences || action === 'view'
                }
                disableTouchListener={
                  !thereIsAxle || locSelectedServiceOffer?.id !== ServiceOfferIds.allPreferences || action === 'view'
                }
                disableFocusListener={
                  !thereIsAxle || locSelectedServiceOffer?.id !== ServiceOfferIds.allPreferences || action === 'view'
                }
              >
                <span>
                  <Select
                    fullWidth
                    required
                    label={t('Profile Type')}
                    value={field.value || ''}
                    options={getOptions('profile_types')}
                    onChange={(e: any) => {
                      field.onChange(e.target.value);
                      setValue('main.vehicle_type_vmrs_code', '', { shouldDirty: true });
                      setValue('main.vehicle_type', '', { shouldDirty: true });
                      setValue('main.vehicle_attrs_tractor', 'all', { shouldDirty: true });
                      setValue('main.vehicle_attrs_trailer', 'all', { shouldDirty: true });
                    }}
                    readOnly={
                      thereIsAxle ||
                      action === 'view' ||
                      locSelectedServiceOffer?.id === ServiceOfferIds.onCall ||
                      locSelectedServiceOffer?.id === ServiceOfferIds.bulkOrder
                    }
                    error={!!formState.errors.main?.profile_type}
                    helperText={fieldState?.error?.message}
                  />
                </span>
              </Tooltip>
            )}
          />
        </Grid>
        <Grid item xs={12} lg={2}>
          <Controller
            name="main.application_type"
            control={control}
            rules={{ required: { value: true, message: t('Application Type is required').toString() } }}
            render={({ field, fieldState }) => (
              <Select
                fullWidth
                required
                label={t('Application Type')}
                value={field.value}
                onChange={(e: any) => field.onChange(e.target.value)}
                options={getOptions('application_types')}
                readOnly={action === 'view'}
                error={!!fieldState.error}
                helperText={fieldState?.error?.message}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} lg={6}>
          <Controller
            name="main.profile_name"
            control={control}
            rules={{ required: { value: true, message: t('Profile Name is required').toString() } }}
            render={({ field, fieldState }) => (
              <TextField
                required
                multiline
                label={t('Profile Name')}
                value={field.value || ''}
                onChange={(e: any) => {
                  field.onChange(e.target.value);
                }}
                readOnly={action === 'view'}
                error={!!fieldState.error}
                helperText={fieldState?.error?.message}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} lg={4}>
          <Controller
            name="main.vehicle_type_vmrs_code"
            control={control}
            rules={{ required: { value: true, message: t('Vehicle Type is required').toString() } }}
            render={({ field, fieldState }) => (
              <Select
                fullWidth
                required
                label={t('Vehicle Type')}
                value={field.value || ''}
                onChange={(e: any) => {
                  const label = getOptionLabel(
                    profileType === 'onsite' ? 'vehicle_types_onsite' : 'vehicle_types_ers',
                    e?.target.value,
                  );

                  field.onChange(e.target.value);

                  setValue('main.vehicle_type', label, { shouldDirty: true });
                  setValue('main.vehicle_attrs_tractor', 'all', { shouldDirty: true });
                  setValue('main.vehicle_attrs_trailer', 'all', { shouldDirty: true });
                }}
                options={getOptions(profileType === 'onsite' ? 'vehicle_types_onsite' : 'vehicle_types_ers')}
                readOnly={action === 'view'}
                error={!!fieldState.error}
                helperText={fieldState?.error?.message}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} lg={6}>
          <Grid container spacing={2}>
            {(isTrailerOrTractor?.type === 'tractor' || isTrailerOrTractor?.type === 'both') && (
              <Grid item xs={12} lg={isTrailerOrTractor?.type === 'both' ? 6 : 12}>
                <Controller
                  name="main.vehicle_attrs_tractor"
                  control={control}
                  render={({ field }) => (
                    <Select
                      fullWidth
                      required={isTrailerOrTractor?.type === 'tractor' || isTrailerOrTractor?.type === 'both'}
                      value={field.value || ''}
                      label={t('Tractor Attribute')}
                      options={getOptions('vehicle_attrs_tractor')}
                      onChange={(e: any) => field.onChange(e.target.value)}
                      readOnly={action === 'view'}
                    />
                  )}
                />
              </Grid>
            )}
            {(isTrailerOrTractor?.type === 'trailer' || isTrailerOrTractor?.type === 'both') && (
              <Grid item xs={12} lg={isTrailerOrTractor?.type === 'both' ? 6 : 12}>
                <Controller
                  name="main.vehicle_attrs_trailer"
                  control={control}
                  render={({ field }) => (
                    <Select
                      fullWidth
                      required={isTrailerOrTractor?.type === 'trailer' || isTrailerOrTractor?.type === 'both'}
                      value={field.value || ''}
                      label={t('Trailer Attribute')}
                      options={filterTrailerOptions()}
                      onChange={(e: any) => field.onChange(e.target.value)}
                      readOnly={action === 'view'}
                    />
                  )}
                />
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid item xs={12} lg={2}>
          <div style={{ display: 'flex', flexDirection: 'column', textAlign: 'right' }}>
            <Typography variant="caption" style={{ opacity: 0.75 }}>
              {t('Driver Can Override')}
            </Typography>
            <Controller
              name="main.driver_can_override"
              control={control}
              render={({ field }) => (
                <Switch
                  checked={field.value}
                  size="small"
                  readOnly={action === 'view'}
                  onChange={(e: any) => field.onChange(e.target.checked)}
                />
              )}
            />
          </div>
        </Grid>
        <Grid item xs={12} lg={4}>
          <Controller
            name="main.off_tire_disposition"
            control={control}
            render={({ field }) => (
              <Select
                fullWidth
                value={field.value || ''}
                label={t('Off tire disposition')}
                options={getOptions('off_tire_disposition')}
                readOnly={action === 'view'}
                onChange={(e: any) => {
                  field.onChange(e.target.value);
                  setValue('main.off_tire_disposition_reason', '', { shouldDirty: true });
                }}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} lg={6}>
          {profileType !== 'ers' && (
            <Controller
              name="main.off_tire_disposition_reason"
              control={control}
              render={({ field }) => (
                <Select
                  fullWidth
                  value={field.value || ''}
                  label={t('Off tire disposition reason')}
                  options={getOptions(
                    offTireDisposition === 'return_with_driver'
                      ? 'off_tire_disposition_reasons_rwd'
                      : 'off_tire_disposition_reasons_kwd',
                  )}
                  readOnly={action === 'view' || !offTireDisposition}
                  onChange={(e: any) => field.onChange(e.target.value)}
                />
              )}
            />
          )}
        </Grid>
        <Grid item xs={12} lg={2}>
          <Tooltip
            arrow
            title={
              t(
                'Exhaust all Michelin tire and retread preferences in priority order with the first service provider',
              ) as string
            }
          >
            <div style={{ display: 'flex', flexDirection: 'column', textAlign: 'right' }}>
              <Typography variant="caption" style={{ opacity: 0.75 }}>
                {t('Single Call Indicator')}
              </Typography>
              <Controller
                name="main.single_call_indicator"
                control={control}
                render={({ field }) => (
                  <Switch
                    size="small"
                    checked={field.value}
                    readOnly={action === 'view' || profileType === 'onsite'}
                    onChange={(e: any) => {
                      field.onChange(e.target.checked);
                    }}
                  />
                )}
              />
            </div>
          </Tooltip>
        </Grid>
        {location?.customer_type !== 'ST' && (
          <Grid item>
            <Grid container>
              <Grid item xs={12} lg={12}>
                <Typography variant="body2">
                  {t(
                    'Assign all locations which will use this Tire Profile. At least one assigned location is required for activation of this Tire Profile',
                  )}
                </Typography>

                {formState.errors.locations && (
                  <Typography variant="body2" color="error">
                    {formState.errors.locations}
                  </Typography>
                )}
              </Grid>
              <Grid item xs={12} lg={12}>
                <Typography variant="body2" color={formState.errors.locations ? 'error' : 'inherit'}>
                  {t('Choose locations to assign this profile to')}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Grid container xs={12} alignItems="center">
                  <Grid item xs={6} lg={6}>
                    <Controller
                      name="main.assign_to_all_locations"
                      control={control}
                      rules={{
                        required: false,
                        validate: {
                          value: (value) =>
                            ((getValues().main?.assigned_locations?.length ?? 0) > 0 && value === false) ||
                            value === true ||
                            t('You must assign to All or selected ship to accounts').toString(),
                        },
                      }}
                      render={({ field: { onChange, value } }) => (
                        <Switch
                          checked={value || false}
                          name="assign_to_all_locations"
                          onChange={(e: any) => {
                            if (e.target.checked) {
                              setLocationsBackup(getValues().main?.assigned_locations || []);
                              setValue('main.assigned_locations', [] as string[], { shouldDirty: true });
                            }
                            if (e.target.checked === false) {
                              setValue('main.assigned_locations', locationsBackup, { shouldDirty: true });
                            }
                            onChange(e.target.checked);
                          }}
                          disabled={
                            action === 'view' ||
                            getValues().owner?.customer_type?.toUpperCase() === 'ST' ||
                            (location?.customer_type || '').toUpperCase() === 'ST'
                          }
                          label={t('Assign to all locations')}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={6} lg={6}>
                    <Controller
                      name="main.assigned_locations"
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <NewLocationAssignments
                          owner={
                            action === 'add' ? location?.customer_number.toString() : getValues().owner?.customer_number
                          }
                          mode={
                            action === 'view' ||
                            getValues().owner?.customer_type?.toUpperCase() === 'ST' ||
                            getValues().main?.assign_to_all_locations
                              ? 'view'
                              : 'edit'
                          }
                          all={getValues().main?.assign_to_all_locations || false}
                          locations={value ? value.map((hash_key) => ({ hash_key })) : []}
                          onUpdate={(newAssignments) => {
                            onChange(newAssignments.map((na) => na.hash_key));
                            trigger('main.assign_to_all_locations');
                          }}
                          error={!!formState.errors.main?.assign_to_all_locations || false}
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )}
        <Grid item xs={12} lg={12} style={{ height: 245 }}>
          <Typography variant="subtitle2">{t('Special Instructions')}</Typography>
          <Controller
            name="main.special_instructions"
            control={control}
            render={({ field: { onChange, value } }) => (
              <ReactQuillEditor
                id="main.special_instructions"
                config={editorConfig}
                content={value || ''}
                disabled={action === 'view'}
                onUpdateEditorValue={(id: string, content: string) => onChange(content)}
              />
            )}
          />
        </Grid>
      </Grid>
    </Panel>
  );
}
