import React, { useEffect, useMemo } from 'react';
import { useMediaQuery, useTheme } from '@material-ui/core';
import { FilterGroup, useDialogs, useListSelectFilter } from '@michelin/acid-components';
import {
  clearMaestroDataSourceCache,
  useMaestroDataSource,
  usePermissions,
  useSnackbar,
  useTranslation,
} from '@michelin/central-provider';
import { MechanicalProfileOVM } from '@michelin/fcp-view-models';
import { getOptions } from '@michelin/select-options-provider';
import { CONTENT_AREA_HEIGHT } from 'components/Util';
import { DataGrid, Template } from 'devextreme-react';
import { Column, Scrolling, SearchPanel } from 'devextreme-react/data-grid';
import { useDeleteMechanicalProfile } from 'hooks/useMechanicalProfile';
import { getBaseUrl } from 'prefs-and-service-offers';
import { useHistory, useParams } from 'react-router-dom';
import { IPermissions } from '../types';
import { mechanicalList } from '../utils';
import { ActionsCell, CreatedByCell, OptionCell, ProfileNameCell, RenderSpecialInstructionsCell } from './Columns';

export interface viewParams {
  selectedAccount: string;
}

export function MechanicalTable() {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const permissions = usePermissions();
  const { confirmDialog } = useDialogs();
  const params = useParams<viewParams>();
  const theme = useTheme();
  const history = useHistory();
  const tabletView = useMediaQuery(theme.breakpoints.down(800));
  const cellView = useMediaQuery(theme.breakpoints.down(500));

  const deleteMechanicalProfile = useDeleteMechanicalProfile();
  const mechanicalPermissions: IPermissions = {
    read: permissions.allowsAction('mechanicals.read'),
    create: permissions.allowsAction('mechanicals.create'),
    update: permissions.allowsAction('mechanicals.update'),
    delete: permissions.allowsAction('mechanicals.delete'),
    list: permissions.allowsAction('mechanicals.list'),
  };

  const profileTypes = useMemo(() => getOptions('profile_types'), []);
  const applicationTypes = useMemo(() => {
    return [{ label: t('N/A'), value: 'n/a', order: 0 }, ...getOptions('application_types')];
  }, [t]);

  const profileTypesFilter = useListSelectFilter({
    label: t('Profile Type'),
    options: profileTypes,
    unselectedLabel: t('Any Level'),
    hashParam: 'profileLevel',
  });

  const creatorFilter = useListSelectFilter({
    label: t('Creator'),
    options: getOptions('account_types', 'all', t('All')),
    hashParam: 'creator',
    defaultValue: 'all',
  });

  const applicationTypeFilter = useListSelectFilter({
    label: t('Application Type'),
    options: applicationTypes,
    hashParam: 'applicationType',
    defaultValue: 'n/a',
  });

  const filterGroup = useMemo(
    () => new FilterGroup([profileTypesFilter.filter, creatorFilter.filter, applicationTypeFilter.filter]),
    [profileTypesFilter.filter, creatorFilter.filter, applicationTypeFilter.filter],
  );

  useEffect(() => {
    if (!mechanicalPermissions.list) {
      enqueueSnackbar(t('User has no permissions to list mechanical profiles.'), { variant: 'warning' });
      history.push(getBaseUrl());
    } else if (!mechanicalPermissions.read) {
      enqueueSnackbar(t('User has no permissions to read mechanical profiles.'), { variant: 'warning' });
      history.push(getBaseUrl());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    clearMaestroDataSourceCache('mechanical-list');
  }, []);

  const ds = useMaestroDataSource({
    API: mechanicalList(params.selectedAccount),
    reqOptions: {
      filters: {
        profileType: profileTypesFilter.value,
        creator: creatorFilter.value === 'all' ? '' : creatorFilter.value,
        applicationType: applicationTypeFilter.value === 'n/a' ? '' : applicationTypeFilter.value,
      },
    },
    componentPlacements: {
      filtersPlacement: tabletView ? 'outside' : 'embeded',
      searchPlacement: cellView ? 'outside' : 'embeded',
    },
    filterGroup,
    cacheKey: 'mechanical-list',
  });

  const reloadAfterAction = () => {
    async function reload() {
      clearMaestroDataSourceCache(`mechanical-list`);
      await ds.dataSource.reload();
    }
    reload();
  };

  const confirmDeleteProfile = (data: MechanicalProfileOVM | undefined) => {
    if (!data || !data.main) return;

    confirmDialog(
      t('Are you sure you want to delete this mechanical profile?'),
      t('Delete Mechanical Profile'),
      t('Yes'),
      t('No'),
      async () => {
        await deleteProfile(data);
      },
      undefined,
      { disableBackdropClick: true, disableEscapeKeyDown: true },
    );
  };

  const deleteProfile = async (mechanicalProfile: MechanicalProfileOVM | undefined) => {
    if (!mechanicalProfile || !mechanicalProfile.id || !mechanicalProfile?.owner?.customer_number) return;

    try {
      const response = await deleteMechanicalProfile.mutateAsync({
        fleetId: mechanicalProfile.owner?.customer_number,
        mechanicalId: mechanicalProfile.id,
      });
      if (!response) {
        enqueueSnackbar(t('Error deleting Mechanical Profile'), {
          variant: 'error',
        });
      } else {
        enqueueSnackbar(t('Mechanical Profile Deleted'), {
          variant: 'success',
        });
      }
    } catch (error) {
      enqueueSnackbar(t('Error deleting Mechanical Profile'), {
        variant: 'error',
      });
    }
    reloadAfterAction();
  };

  return (
    <>
      <DataGrid
        style={{ height: CONTENT_AREA_HEIGHT }}
        noDataText={t('No Mechanical profiles found...')}
        className="freespaced-table"
        dataSource={ds.dataSource}
        allowColumnReordering
        wordWrapEnabled
        remoteOperations
        onToolbarPreparing={ds.onToolbarPreparing}
        scrolling={{ mode: 'virtual', rowRenderingMode: 'virtual' }}
      >
        <SearchPanel visible />
        <Scrolling mode="virtual" />
        <Template name="filterFields" render={ds.renderFilterFields} />
        <Template name="resultsCounter" render={ds.renderResultsCounter} />
        <Column
          caption={t('Creator')}
          alignment="center"
          allowSorting
          dataField="creator"
          cellRender={CreatedByCell}
          width={100}
        />
        <Column
          caption={t('Profile Name')}
          allowSorting
          dataField="profile_name"
          cellRender={({ row }) => <ProfileNameCell row={row} />}
          minWidth={200}
        />

        <Column
          caption={t('Profile Type')}
          cellRender={({ row }) => <OptionCell option="profile_types" value={row.data.main.profile_type} />}
          allowSorting
          dataField="profile_type"
          minWidth={150}
        />
        <Column
          caption={t('Application Type')}
          cellRender={({ row }) => <OptionCell option="application_types" value={row.data.main.application_type} />}
          allowSorting
          dataField="application"
          minWidth={150}
        />
        <Column
          caption={t('Special Instructions')}
          cellRender={({ row }) => (
            <RenderSpecialInstructionsCell htmlString={String(row.data.main.special_instructions || '')} />
          )}
          allowSorting={false}
          dataField="instructions"
          minWidth={200}
        />
        <Column
          allowSearch={false}
          allowSorting={false}
          width={120}
          cellRender={(row) => (
            <ActionsCell row={row.data as MechanicalProfileOVM} confirmDeleteProfile={confirmDeleteProfile} />
          )}
        />
      </DataGrid>
    </>
  );
}
