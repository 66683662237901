import React from 'react';
import { Add } from '@material-ui/icons';
import { Container, Fab, GlobalActions, Panel } from '@michelin/acid-components';
import { usePermissions, useTranslation } from '@michelin/central-provider';
import { PageTitle } from 'components/PageTitle/PageTitle';
import { getBaseUrl } from 'prefs-and-service-offers';
import { useHistory } from 'react-router-dom';
import { MechanicalTable } from './Components/Table';

export function MechanicalList() {
  const { t } = useTranslation();
  const history = useHistory();
  const permissions = usePermissions();

  const handleFabCreateClick = () => {
    history.push(`${getBaseUrl()}/mechanical-profile/create`);
  };

  const ActionButtonsControl = () => (
    <GlobalActions>
      {permissions.allowsAction('mechanicals.create') && (
        <Fab color="primary" label={t('Create')} onClick={handleFabCreateClick}>
          <Add />
        </Fab>
      )}
    </GlobalActions>
  );

  return (
    <>
      <PageTitle title={t('Mechanical Profile List')} />
      <Container>
        <Panel spacing={0}>
          <MechanicalTable />
          <ActionButtonsControl />
        </Panel>
      </Container>
    </>
  );
}
