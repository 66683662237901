import React, { FunctionComponent, useEffect, useState } from 'react';
import { Grid, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import { ArrowBack, Cancel, Edit, Save, Timelapse } from '@material-ui/icons';
import {
  Autocomplete,
  Fab,
  GlobalActions,
  LoadingBounce,
  Panel,
  PreventTransitionPrompt,
  Select,
  Switch,
  TextField,
  useDialogs,
} from '@michelin/acid-components';
import { usePermissions, useSnackbar, useTranslation } from '@michelin/central-provider';
import { MechanicalProfileOVM } from '@michelin/fcp-view-models';
import { getOptions } from '@michelin/select-options-provider';
import { PlainMessage } from 'components/Messages';
import { PageTitle } from 'components/PageTitle/PageTitle';
import { ReactQuillEditor } from 'components/ReactQuillEditor/ReactQuillEditor';
import { scrollToDisplayFirstError } from 'components/Util';
import { useGetMechanicalProfile, useSaveMechanicalProfile } from 'hooks/useMechanicalProfile';
import { getBaseUrl } from 'prefs-and-service-offers';
import { Controller, useForm } from 'react-hook-form';
import { useHistory, useParams } from 'react-router-dom';
import { getAutocompleteValues } from 'utils/reusableMethods';
import { Actions, IPermissions } from './types';

type IProps = {
  action: Actions;
};
interface viewParams {
  selectedAccount: string;
  profileId: string;
}

export const MechanicalDetails: FunctionComponent<IProps> = (props: IProps) => {
  const { action } = props;
  const { enqueueSnackbar } = useSnackbar();
  const { errorDialog } = useDialogs();
  const { t } = useTranslation();
  const history = useHistory();
  const permissions = usePermissions();
  const params = useParams<viewParams>();
  const theme = useTheme();
  const isSmUp = useMediaQuery(theme.breakpoints.up('sm'));

  const [saving, setSaving] = useState<boolean>(false);

  const editorConfig = {
    readOnly: action === 'view',
    isEditing: action !== 'view',
  };

  const mechanicalPermisions: IPermissions = {
    read: permissions.allowsAction('mechanicals.read'),
    create: permissions.allowsAction('mechanicals.create'),
    update: permissions.allowsAction('mechanicals.update'),
    delete: permissions.allowsAction('mechanicals.delete'),
    list: permissions.allowsAction('mechanicals.list'),
  };

  // Control permissions
  if (mechanicalPermisions && mechanicalPermisions.read === false) {
    enqueueSnackbar(
      t('Your user does not have enough permissions to read the information about this mechancial profile.'),
      {
        variant: 'warning',
      },
    );
    history.push(getBaseUrl());
  } else if (mechanicalPermisions && mechanicalPermisions.update === false && props.action.toLowerCase() === 'edit') {
    enqueueSnackbar(
      t('Your user does not have enough permissions to edit the information about this mechanical profile.'),
      {
        variant: 'warning',
      },
    );
    history.push(getBaseUrl());
  } else if (mechanicalPermisions && mechanicalPermisions.create === false && props.action.toLowerCase() === 'create') {
    enqueueSnackbar(t('Your user does not have enough permissions to create a mechanical profile.'), {
      variant: 'warning',
    });
    history.push(getBaseUrl());
  }

  const { control, handleSubmit, getValues, formState, reset } = useForm<MechanicalProfileOVM>({
    mode: 'all',
    reValidateMode: 'onChange',
    defaultValues: {
      allowedActions: { update: false },
      main: {
        application_type: '',
        driver_can_override: false,
        profile_name: '',
        profile_type: '',
        special_instructions: '<p><br></p>',
        allowed_to_call_in_requests: [],
      },
    } as MechanicalProfileOVM,
  });
  const isDirty = Object.keys(formState.dirtyFields).length > 0;
  const getMechanicalProfile = useGetMechanicalProfile({
    accountNumber: params.selectedAccount,
    profileId: params.profileId ? params.profileId : '',
    action: action,
  });
  const saveMechanicalProfileMutation = useSaveMechanicalProfile({
    accountNumber: params.selectedAccount,
    profileId: params.profileId ? params.profileId : '',
  });

  useEffect(() => {
    setSaving(false);
  }, [props.action]);

  useEffect(() => {
    if (getMechanicalProfile.isFetching || !getMechanicalProfile.data) return;
    reset(getMechanicalProfile.data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getMechanicalProfile.isFetching, getMechanicalProfile.data]);

  const handleFabEditClick = () => {
    history.push(`${getBaseUrl()}/mechanical-profile/${params.profileId}/edit`);
  };

  const handleFabBackClick = () => {
    history.push(`${getBaseUrl()}/mechanical-profile`);
  };

  const handleFabCancelClick = () => {
    if (props.action.toLowerCase() === 'edit') {
      history.push(`${getBaseUrl()}/mechanical-profile/${params.profileId}/view`);
    } else {
      history.push(`${getBaseUrl()}/mechanical-profile`);
    }
  };

  const handleFabSaveClick = async (data: MechanicalProfileOVM) => {
    setSaving(true);
    if (!isDirty) {
      setSaving(false);
      return null;
    }
    try {
      const res = await saveMechanicalProfileMutation.mutateAsync({
        accountNumber: params.selectedAccount,
        data,
        action,
      });
      if (res.id) {
        if (action === 'edit') {
          reset(res as MechanicalProfileOVM);
          history.push('view');
          enqueueSnackbar(t('Mechanical profile information saved.'), {
            variant: 'success',
            anchorOrigin: { vertical: 'top', horizontal: 'center' },
          });
        }
        if (action === 'create') {
          const id = res.id || '';
          history.push(`${getBaseUrl()}/mechanical-profile/${id}/view`);
          enqueueSnackbar(t('Mechanical Profile Was Created.'), {
            variant: 'success',
            anchorOrigin: { vertical: 'top', horizontal: 'center' },
          });
        }
      }
    } catch (error) {
      let errorMessage = (error as any)?.message.toString() || t('Error saving Mechanical Profile.');
      if (errorMessage === 'Duplicated profile') {
        errorMessage = t(
          'For each location, the combination of Profile Name, Profile Type, and Application Type must be unique.',
        );
      }

      enqueueSnackbar(errorMessage, {
        variant: 'error',
        anchorOrigin: { vertical: 'top', horizontal: 'center' },
      });
    }
    setSaving(false);
  };

  const onError = (errors: any) => {
    const msg = [];

    Object.keys(errors.main).forEach((err) => {
      msg.push(`• ${t(errors['main'][err]['message'])}`);
    });

    if (msg.length) {
      msg.unshift(t('The following fields are required:'), ' ');
      errorDialog(msg, t('Missing required fields'), t('Ok'));
      scrollToDisplayFirstError();
    } else {
      errorDialog('Plase check the required fields', t('Missing required fields'), t('Ok'));
    }
  };

  const ActionButtonsControlFab = () => (
    <GlobalActions>
      <Fab
        color="primary"
        label={t('Edit')}
        onClick={handleFabEditClick}
        disabled={action.toLowerCase() !== 'view' || !getValues()?.allowedActions?.update}
      >
        <Edit />
      </Fab>
      <Fab
        color={isDirty ? 'primary' : 'inherit'}
        disabled={action.toLowerCase() === 'view' || !isDirty || Object.keys(formState.errors).length !== 0 || saving}
        label={t('Save')}
        onClick={handleSubmit(handleFabSaveClick, onError)}
        displayDisabled={action.toLowerCase() !== 'view' && !saving}
      >
        <Save />
      </Fab>
      <Fab
        color={'primary'}
        disabled={action.toLowerCase() === 'view' || saving}
        label={t('Cancel')}
        onClick={handleFabCancelClick}
      >
        <Cancel />
      </Fab>
      <Fab color="inherit" label={t('Saving')} disabled={!saving}>
        <Timelapse />
      </Fab>
      <Fab color="primary" label={t('Back')} disabled={action !== 'view'} onClick={handleFabBackClick}>
        <ArrowBack />
      </Fab>
    </GlobalActions>
  );

  if (getMechanicalProfile.isError) {
    return (
      <Panel variant="none" style={{ border: 0 }}>
        <Grid container>
          <Grid item xs={12} spacing={2}>
            <PlainMessage
              title={t('Database Error')}
              messages={[
                t('Application could not load wheel profile data.'),
                t('Please try again later or contact support if the error persists'),
              ]}
            />
          </Grid>
        </Grid>
      </Panel>
    );
  }

  if (
    (getMechanicalProfile.isLoading || getMechanicalProfile.isFetching || getMechanicalProfile.isRefetching) &&
    props.action !== 'create'
  )
    return (
      <Panel>
        <LoadingBounce style={{ height: '80vh' }} />
      </Panel>
    );

  return (
    <>
      <PageTitle
        title={action.toLowerCase() === 'create' ? t('Create Mechanical Profile') : t('Mechanical Profile Details')}
      />
      <ActionButtonsControlFab />
      {(props.action === 'create' || getMechanicalProfile.data) && (
        <div
          style={{
            padding: 16,
          }}
        >
          <Panel bodyHeight={'80vh'}>
            <Grid container spacing={2}>
              <Grid item xs={12} lg={12}>
                <Controller
                  name="main.profile_name"
                  control={control}
                  rules={{ required: { value: true, message: t('Profile Name is required').toString() } }}
                  render={({ field, fieldState }) => (
                    <TextField
                      required
                      multiline
                      label={t('Profile Name')}
                      value={field.value || ''}
                      onChange={(e: any) => {
                        field.onChange(e.target.value);
                      }}
                      readOnly={action === 'view'}
                      error={!!fieldState.error}
                      helperText={fieldState?.error?.message}
                      maxLength={100}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} lg={5}>
                <Controller
                  name="main.profile_type"
                  control={control}
                  rules={{ required: { value: true, message: t('Profile Type is required').toString() } }}
                  render={({ field, fieldState }) => (
                    <span>
                      <Select
                        fullWidth
                        required
                        label={t('Profile Type')}
                        value={field.value || ''}
                        options={getOptions('profile_types')}
                        onChange={(e: any) => {
                          field.onChange(e.target.value);
                        }}
                        readOnly={action === 'view'}
                        error={!!formState.errors.main?.profile_type}
                        helperText={fieldState?.error?.message}
                      />
                    </span>
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={9} lg={5}>
                <Controller
                  name="main.application_type"
                  control={control}
                  rules={{ required: { value: true, message: t('Application Type is required').toString() } }}
                  render={({ field, fieldState }) => (
                    <Select
                      fullWidth
                      required
                      label={t('Application Type')}
                      value={field.value}
                      onChange={(e: any) => field.onChange(e.target.value)}
                      options={getOptions('application_types')}
                      readOnly={action === 'view'}
                      error={!!fieldState.error}
                      helperText={fieldState?.error?.message}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={4} sm={3} lg={2}>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: isSmUp ? 'column' : 'row',
                    textAlign: isSmUp ? 'right' : 'left',
                  }}
                >
                  <Typography variant="caption" style={{ opacity: 0.75 }}>
                    {t('Driver Can Override')}
                  </Typography>
                  <Controller
                    name="main.driver_can_override"
                    control={control}
                    render={({ field }) => (
                      <Switch
                        checked={field.value}
                        size="small"
                        readOnly={action === 'view'}
                        onChange={(e: any) => field.onChange(e.target.checked)}
                        fullWidth={false}
                      />
                    )}
                  />
                </div>
              </Grid>
              <Grid item xs={12} lg={5}>
                <Controller
                  name="main.allowed_to_call_in_requests"
                  control={control}
                  render={({ field }) => (
                    <>
                      <Autocomplete
                        id={`MechanicalAllowedToCallInRequests`}
                        disableCloseOnSelect
                        options={getOptions('mechanical_allowed_to_call_in_requests')}
                        label={t('Allowed to call in requests ')}
                        placeholder={t('Select')}
                        value={getAutocompleteValues(field.value, getOptions('mechanical_allowed_to_call_in_requests'))}
                        readOnly={action === 'view'}
                        onChange={(e, values) => {
                          field.onChange(values.map((v) => v.value) || []);
                        }}
                      />
                    </>
                  )}
                />
              </Grid>

              <Grid item xs={12} lg={12} style={{ height: 245 }}>
                <Typography
                  variant="subtitle2"
                  style={{ color: formState.errors.main?.special_instructions ? 'red' : 'inherit' }}
                >
                  {t('Special Instructions') + ' *'}
                </Typography>
                <Controller
                  name="main.special_instructions"
                  control={control}
                  rules={{
                    required: { value: true, message: t('Special Instructions are required').toString() },
                    validate: (value) =>
                      (value?.trim() !== '' && value !== '<p><br></p>') ||
                      t('Special Instructions are required').toString(),
                  }}
                  render={({ field: { onChange, value } }) => (
                    <ReactQuillEditor
                      id="main.special_instructions"
                      config={editorConfig}
                      content={value || ''}
                      disabled={action === 'view'}
                      readOnly={action === 'view'}
                      onUpdateEditorValue={(id: string, content: string) => onChange(content)}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </Panel>
        </div>
      )}

      <PreventTransitionPrompt when={isDirty && !saving && action !== 'view'} handleDiscard={() => reset()} />
    </>
  );
};
