import { useTranslation } from '@michelin/central-provider';
import { Auth, auth } from 'Auth';

export enum PreferenceType {
  locations = 'locations',
  tires = 'tires',
  wheels = 'wheels',
  billing = 'billing',
  contacts = 'contacts',
  sop = 'sop',
  sopRefactor = 'sopRefactor',
  accounts = 'accounts',
  vehicles = 'vehicles',
  mechanical= 'mechanical',
}

export interface Preference {
  path: string;
  icon: string;
  preference: PreferenceType;
  title: string;
  description: string;
  position?: string;
  onCallFlag?: boolean;
  serviceOffer?: ServiceOffer | undefined;
}

export interface ServiceOffer {
  id: 'all-preferences' | 'oncall' | 'bulk-order';
  path: string;
  image: string;
  icon: string;
  title: string;
  shortTitle: string;
  longTitle: string;
  description: string;
}

export interface SelectedServiceOffer {
  id: string;
  path: string;
  shortTitle: string;
}

export const ServiceOfferIds = {
  allPreferences: 'all-preferences',
  onCall: 'oncall',
  bulkOrder: 'bulk-order',
};

const GetServiceOffers = (translate: boolean = true): ServiceOffer[] => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { t } = translate ? useTranslation() : { t: (a: string) => a };

  return [
    {
      id: 'all-preferences',
      path: `/${ServiceOfferIds.allPreferences}/all-preferences`,
      shortTitle: t('All Preferences'),
      longTitle: t('All Preferences Modules - Profiles & Preferences'),
      title: t('Manage All Preferences'),
      image: 'all-preferences.jpg',
      icon: 'all-preferences',
      description: t('Manage All Profiles & Preferences by Module Type'),
    },
    {
      id: 'oncall',
      path: `/${ServiceOfferIds.onCall}/oncall`,
      shortTitle: t('ONCall'),
      longTitle: t('ONCall Modules - Profiles & Preferences'),
      title: t('Michelin ONCall'),
      image: 'oncall.jpg',
      icon: 'oncall',
      description: t('Manage Profiles & Preferences for ONCall ERS Service'),
    },
    {
      id: 'bulk-order',
      path: `/${ServiceOfferIds.bulkOrder}/bulk-order`,
      shortTitle: t('Fleet Order'),
      longTitle: t('Fleet Order Modules - Profiles & Preferences'),
      title: t('Michelin Fleet Order'),
      image: 'fleet-ordering.jpg',
      icon: 'bulk-order',
      description: t('Manage Profiles & Preferences for the eCommerce Fleet Order Marketplace'),
    },
  ];
};

const prefLocation: Preference = {
  path: '/locations',
  icon: 'locations',
  preference: PreferenceType.locations,
  title: 'Locations',
  description: 'Terminal, Shop, & Dropyard Preferences',
  serviceOffer: undefined,
};

const prefContacts: Preference = {
  path: '/contacts',
  icon: 'contacts',
  preference: PreferenceType.contacts,
  title: 'Contacts',
  description: 'Contacts & Notifications Preferences',
  serviceOffer: undefined,
};

const prefAccounts: Preference = {
  path: '/accounts',
  icon: 'accounts',
  preference: PreferenceType.accounts,
  title: 'Accounts',
  description: 'Service Provider & National Account Preferences',
  serviceOffer: undefined,
};

const prefBillings: Preference = {
  path: '/billings',
  icon: 'billing',
  preference: PreferenceType.billing,
  title: 'Billing',
  description: 'PO Policy, Pre-Authorization, Limits & Billing for DR Claim Preferences',
  serviceOffer: undefined,
};

const prefTires: Preference = {
  path: '/tire-profile',
  icon: 'tires',
  preference: PreferenceType.tires,
  title: 'Tires',
  description: 'ERS & On-site Tire and Retread Preferences',
  serviceOffer: undefined,
};

const prefWheels: Preference = {
  path: '/wheels',
  icon: 'wheels',
  preference: PreferenceType.wheels,
  title: 'Wheels',
  description: 'Wheel Preferences',
  serviceOffer: undefined,
};

const prefSopRefactor: Preference = {
  path: '/sop/list',
  icon: 'sop',
  preference: PreferenceType.sopRefactor,
  title: 'SOP',
  description: 'Manage Profile & Preferences for Standard Operating Procedures',
  serviceOffer: undefined,
};

const prefVehicles: Preference = {
  path: '/commercial-vehicles',
  icon: 'vehicles',
  preference: PreferenceType.vehicles,
  title: 'Vehicle Management',
  description: 'Vehicle Management',
  serviceOffer: undefined,
};

const prefUrbanVehicles: Preference = {
  path: '/urban-vehicles',
  icon: 'vehicles',
  preference: PreferenceType.vehicles,
  title: 'Vehicle Management',
  description: 'Vehicle Management',
  serviceOffer: undefined,
};

const prefMechanical: Preference = {
  path: '/mechanical-profile',
  icon: 'mechanical',
  preference: PreferenceType.mechanical,
  title: 'Mechanical',
  description: 'Mechanical Preferences',
  serviceOffer: undefined,
};

export const buildPreferences = (auth: Auth, preferencesType: string): Preference[] => {
  const permissions = auth.usePermissions();
  const prefs = [];

  if (permissions?.allowsAction('locations.list') && permissions?.allowsAction('locations.read')) {
    let path: string | null;
    switch (preferencesType) {
      case 'onCallPreferences':
        path = `/${ServiceOfferIds.onCall}${prefLocation.path}`;
        break;
      case 'bulkOrderPreferences':
        path = `/${ServiceOfferIds.bulkOrder}${prefLocation.path}`;
        break;
      case 'allPreferences':
        path = `/${ServiceOfferIds.allPreferences}${prefLocation.path}`;
        break;
      default:
        path = null;
        break;
    }
    if (path) prefs.push({ ...prefLocation, path });
  }

  if (permissions?.allowsAction('contacts.list') && permissions?.allowsAction('contacts.read')) {
    let path: string | null;
    switch (preferencesType) {
      case 'onCallPreferences':
        path = `/${ServiceOfferIds.onCall}${prefContacts.path}`;
        break;
      case 'bulkOrderPreferences':
        path = `/${ServiceOfferIds.bulkOrder}${prefContacts.path}`;
        break;
      case 'allPreferences':
        path = `/${ServiceOfferIds.allPreferences}${prefContacts.path}`;
        break;
      default:
        path = null;
        break;
    }
    if (path) prefs.push({ ...prefContacts, path });
  }

  if (permissions?.allowsAction('accounts.list') && permissions?.allowsAction('accounts.read')) {
    let path: string | null;
    switch (preferencesType) {
      case 'onCallPreferences':
        path = `/${ServiceOfferIds.onCall}${prefAccounts.path}`;
        break;
      case 'allPreferences':
        path = `/${ServiceOfferIds.allPreferences}${prefAccounts.path}`;
        break;
      default:
        path = null;
        break;
    }
    if (path) prefs.push({ ...prefAccounts, path });
  }

  if (permissions?.allowsAction('billing.list') && permissions?.allowsAction('billing.read')) {
    let path: string | null;
    switch (preferencesType) {
      case 'onCallPreferences':
        path = `/${ServiceOfferIds.onCall}${prefBillings.path}`;
        break;
      case 'bulkOrderPreferences':
        path = `/${ServiceOfferIds.bulkOrder}${prefBillings.path}`;
        break;
      case 'allPreferences':
        path = `/${ServiceOfferIds.allPreferences}${prefBillings.path}`;
        break;
      default:
        path = null;
        break;
    }
    if (path) prefs.push({ ...prefBillings, path });
  }

  if (permissions?.allowsAction('tires.list') && permissions?.allowsAction('tires.read')) {
    let path: string | null;
    switch (preferencesType) {
      case 'onCallPreferences':
        path = `/${ServiceOfferIds.onCall}${prefTires.path}`;
        break;
      case 'bulkOrderPreferences':
        path = `/${ServiceOfferIds.bulkOrder}${prefTires.path}`;
        break;
      case 'allPreferences':
        path = `/${ServiceOfferIds.allPreferences}${prefTires.path}`;
        break;
      default:
        path = null;
        break;
    }
    if (path) prefs.push({ ...prefTires, path });
  }

  if (permissions?.allowsAction('wheels.list') && permissions?.allowsAction('wheels.read')) {
    let path: string | null;
    switch (preferencesType) {
      case 'onCallPreferences':
        path = `/${ServiceOfferIds.onCall}${prefWheels.path}`;
        break;
      case 'allPreferences':
        path = `/${ServiceOfferIds.allPreferences}${prefWheels.path}`;
        break;
      default:
        path = null;
        break;
    }
    if (path) prefs.push({ ...prefWheels, path });
  }

  if (permissions?.allowsAction('mechanicals.list') && permissions?.allowsAction('mechanicals.read')) {
    let path: string | null;
    switch (preferencesType) {
      case 'onCallPreferences':
        path = `/${ServiceOfferIds.onCall}${prefMechanical.path}`;
        break;
      case 'allPreferences':
        path = `/${ServiceOfferIds.allPreferences}${prefMechanical.path}`;
        break;
      default:
        path = null;
        break;
    }
    if (path) prefs.push({ ...prefMechanical, path });
  }

  if (
    permissions?.allowsAction('sop.list') &&
    permissions?.allowsAction('sop.read') &&
    preferencesType === 'allPreferences'
  ) {
    prefs.push({ ...prefSopRefactor, path: `/${ServiceOfferIds.allPreferences}${prefSopRefactor.path}` });
  }

  if (
    !permissions?.allowsAction('vehicles.commercial') &&
    permissions?.allowsAction('vehicles.urban') &&
    permissions?.allowsAction('vehicles.list') &&
    permissions?.allowsAction('vehicles.read') &&
    auth.isUrbanCustomer() &&
    auth.getCountryCode() === 'us'
  ) {
    let path: string | null;
    switch (preferencesType) {
      case 'bulkOrderPreferences':
        path = `/${ServiceOfferIds.bulkOrder}${prefUrbanVehicles.path}`;
        break;
      case 'allPreferences':
        path = `/${ServiceOfferIds.allPreferences}${prefUrbanVehicles.path}`;
        break;
      default:
        path = null;
        break;
    }
    if (path) prefs.push({ ...prefVehicles, path });
  }

  if (
    permissions?.allowsAction('vehicles.commercial') &&
    permissions?.allowsAction('vehicles.list') &&
    permissions?.allowsAction('vehicles.read') &&
    auth.getCountryCode() === 'us'
  ) {
    let path: string | null;
    switch (preferencesType) {
      case 'bulkOrderPreferences':
        path = `/${ServiceOfferIds.bulkOrder}${prefVehicles.path}`;
        break;
      case 'allPreferences':
        path = `/${ServiceOfferIds.allPreferences}${prefVehicles.path}`;
        break;
      default:
        path = null;
        break;
    }
    if (path) prefs.push({ ...prefVehicles, path });
  }

  return prefs;
};

export const getSelectedServiceOffer = (): SelectedServiceOffer => {
  const id = window.location.pathname.split('/')[2] || 'all-preferences';
  const res = GetServiceOffers(false).find((so) => so.id === id);
  if (!res) throw new Error('INVALID SERVICE OFFER!');
  return res;
};

export const getBaseUrl = (useSelectedServiceOffer: boolean = true) => {
  const accountNumber = auth.getCustomerNumber();
  if (!accountNumber) return '/';
  if (!useSelectedServiceOffer) return `/${accountNumber}`;
  const selectedServiceOffer = getSelectedServiceOffer();
  if (!selectedServiceOffer || !selectedServiceOffer.id) {
    return `/${accountNumber}`;
  }
  return `/${accountNumber}/${selectedServiceOffer.id}`;
};

export const getHomeUrl = (useSelectedServiceOffer: boolean = true) => {
  const selectedServiceOffer = getSelectedServiceOffer();
  return `${getBaseUrl(useSelectedServiceOffer)}/${selectedServiceOffer.id}`;
};

export default {
  GetServiceOffers,
};
