import React, { useEffect, useState } from 'react';
import { Box, Grid, Typography } from '@material-ui/core';
import { Add, ArrowBack, Cancel, Edit, Save, Timelapse } from '@material-ui/icons';
import FileCopyOutlined from '@material-ui/icons/FileCopyOutlined';
import {
  Button,
  Container,
  Fab,
  GlobalActions,
  IconButton,
  LoadingBounce,
  Panel,
  PreventTransitionPrompt,
  Tab,
  TabBar,
  Tabs,
  Tooltip,
  useDialogs,
} from '@michelin/acid-components';
import { usePermissions, useSnackbar, useTranslation } from '@michelin/central-provider';
import { ProductOVM, TireProfileAxle, TireProfileOVM } from '@michelin/fcp-view-models';
import { getOptionLabel } from '@michelin/select-options-provider';
import LastModified from 'components/ChangeLog/LastModified';
import { PlainMessage } from 'components/Messages';
import { PageTitle } from 'components/PageTitle/PageTitle';
import { useGetProducts, useGetTireProfile, useSaveTireProfile } from 'hooks/useTireProfile';
import { AxleTab } from 'modules/Tires/Details/Tabs/AxleTab';
import { MainTab } from 'modules/Tires/Details/Tabs/MainTab';
import { AXLES, Actions, AxleName, Axles, IAxleErrors, TireProfileParams } from 'modules/Tires/types';
import { SelectedServiceOffer, ServiceOfferIds, getBaseUrl, getSelectedServiceOffer } from 'prefs-and-service-offers';
import { FormProvider, UseFormReturn, useForm, useFormContext } from 'react-hook-form';
import { useHistory, useParams } from 'react-router-dom';
import { AddTireSize } from './Dialogs/AddTireSize';
import { AssignAxlePositions } from './Dialogs/AssignAxlePositions';
import { CloneAxlePositions } from './Dialogs/CloneAxlePositions';
import { UniqueErrorData } from './utils';

interface props {
  action: Actions;
  methods: UseFormReturn<TireProfileOVM, any, undefined>;
  products: ProductOVM[];
}

interface TabPanelProps {
  children: React.ReactNode | undefined;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`nav-tabpanel-${index}`}
      aria-labelledby={`nav-tab-${index}`}
    >
      <Box style={{ backgroundColor: 'white' }} p={2}>
        {children}
      </Box>
    </Typography>
  );
}

function Details({ action, methods, products }: props) {
  const { t } = useTranslation();
  const { selectedAccount } = useParams<TireProfileParams>();

  const [selectedTab, setSelectedTab] = useState<number>(0);
  const [selectedAxle, setSelectedAxle] = useState<AxleName>('all_axles');

  const { errorDialog, confirmDialog } = useDialogs();
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();

  const [axles, setAxles] = useState<Axles | null>(null);
  const [axleErrors] = useState<IAxleErrors>();

  const [showCloneAxlePositionDialog, setShowCloneAxlePositionDialog] = useState<boolean>(false);
  const [showAssignAxlePositionDialog, setShowAssignAxlePositionDialog] = useState<boolean>(false);
  const [showAddTireSizeDialog, setShowAddTireSizeDialog] = useState<boolean>(false);
  const [profileTypeSeted, setProfileTypeSeted] = useState<boolean>(false);

  const { getValues, formState, reset } = useFormContext<TireProfileOVM>();
  const tireProfile = getValues();
  const mutateTireProfile = useSaveTireProfile(selectedAccount, getValues()?.id);
  const locSelectedServiceOffer: SelectedServiceOffer | undefined = getSelectedServiceOffer() || undefined;

  const isDirty =
    Object.keys(formState.dirtyFields).length > 0 ||
    (axles && Object.keys(axles).filter((axleKey) => axles[axleKey as keyof Axles].dirty).length > 0) ||
    false;
  const [saving, setSaving] = useState<boolean>(false);
  const allowsEdit =
    (getValues()?.allowedActions?.update &&
      (locSelectedServiceOffer?.id === ServiceOfferIds.allPreferences ||
        (locSelectedServiceOffer?.id === ServiceOfferIds.bulkOrder && getValues()?.main?.profile_type === 'onsite') ||
        (locSelectedServiceOffer?.id === ServiceOfferIds.onCall && getValues()?.main?.profile_type === 'ers'))) ||
    action === 'add';
  useEffect(() => {
    const defaultValue = {} as Axles;
    const tireProfileAxle = tireProfile.axles;

    if (!tireProfileAxle) return;

    AXLES.forEach((axle) => {
      const value = (tireProfileAxle[axle.name]?.tire_replacement_priority || []).length > 0;

      defaultValue[axle.name] = {
        value: value,
        oldValue: value,
        dirty: false,
      };
    });

    setAxles(defaultValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [action]);

  useEffect(() => {
    const axle = AXLES.filter((v) => v.index === selectedTab)[0];
    if (axle) setSelectedAxle(axle.name);
  }, [selectedTab]);

  useEffect(() => {
    setSaving(false);
  }, [action]);

  const typeProfileWatch = methods.watch('main.profile_type');
  useEffect(() => {
    if (!typeProfileWatch) return;
    setProfileTypeSeted(true);
  }, [typeProfileWatch]);

  const getAxleValueBySelectedTab = (tab: number): string => {
    const axle = AXLES.filter((axle) => axle.index === tab)[0];
    if (!axle || selectedTab === 0) return '';
    return axle.name;
  };

  const handleCloneAxle = () => {
    setShowCloneAxlePositionDialog(true);
  };

  const checkTabError = (axleTab: AxleName | 'main') => {
    switch (axleTab) {
      case 'main':
        return formState.errors.main && Object.entries(formState.errors.main).length > 0;
      case 'steer':
        return formState.errors.axles && Object.entries(formState.errors.axles.steer || {}).length > 0;
      case 'trailer':
        return formState.errors.axles && Object.entries(formState.errors.axles.trailer || {}).length > 0;
      case 'lift':
        return formState.errors.axles && Object.entries(formState.errors.axles.lift || {}).length > 0;
      case 'tag':
        return formState.errors.axles && Object.entries(formState.errors.axles.tag || {}).length > 0;
      case 'drive':
        return formState.errors.axles && Object.entries(formState.errors.axles.drive || {}).length > 0;
      case 'carry':
        return formState.errors.axles && Object.entries(formState.errors.axles.carry || {}).length > 0;
      case 'pusher':
        return formState.errors.axles && Object.entries(formState.errors.axles.pusher || {}).length > 0;
      case 'all_axles':
        return formState.errors.axles && Object.entries(formState.errors.axles.all_axles || {}).length > 0;
      default:
        return false;
    }
  };

  const save = async (data: TireProfileOVM) => {
    if (!data) return;
    setSaving(true);
    if (!axles?.all_axles.value) delete data.axles?.all_axles;
    if (!axles?.steer.value) delete data.axles?.steer;
    if (!axles?.drive.value) delete data.axles?.drive;
    if (!axles?.trailer.value) delete data.axles?.trailer;
    if (!axles?.carry.value) delete data.axles?.carry;
    if (!axles?.lift.value) delete data.axles?.lift;
    if (!axles?.pusher.value) delete data.axles?.pusher;
    if (!axles?.tag.value) delete data.axles?.tag;

    let keys: AxleName[] = [];
    if (data.axles) {
      keys = Object.keys(data.axles) as AxleName[];
    }

    if (keys.length === 0) {
      errorDialog(t('You must have at least one axle defined').split('\n'), t('Missing requiered fields'));
      setSaving(false);
      return;
    }

    if (keys.length > 0) {
      const axlesWithoutPriorityLabel: string[] = [];
      const axlesWithoutPriorityValue: AxleName[] = [];

      for (let i = 0; i < keys.length; i++) {
        const axle: TireProfileAxle = (data.axles as any)[keys[i]];
        if (
          axle &&
          (!axle.tire_replacement_priority ||
            (axle.tire_replacement_priority && axle.tire_replacement_priority.length === 0))
        ) {
          axlesWithoutPriorityLabel.push(getOptionLabel('axle_types', keys[i]));
          axlesWithoutPriorityValue.push(keys[i]);
        }
      }
      if (axlesWithoutPriorityLabel.length > 0) {
        errorDialog(
          t('The following axles must have at least one tire replacement priority defined: ') +
            axlesWithoutPriorityLabel.join(', ') +
            '.',
          t('Missing requiered fields'),
        );
        axlesWithoutPriorityValue.forEach((axle) => {
          methods.setError(`axles.${axle}.tire_replacement_priority`, {
            type: 'required',
            message: 'Please add a Tire Replacement Priority.',
          });
        });
        setSaving(false);
        return;
      }
    }

    let competitorCheck = false;
    for (let i = 0; i < keys.length; i++) {
      const axle: TireProfileAxle = (data.axles as any)[keys[i]];
      if (axle && axle.tire_replacement_priority && axle.tire_replacement_priority.length > 0) {
        competitorCheck =
          undefined !==
          axle.tire_replacement_priority.find(
            (x: any) =>
              x.brand && !/^\s*$/.test(x.brand) && !/michelin|uniroyal|mrt|oliver|goodrich|mega\s?mile/gi.test(x.brand),
          );
      }
      if (competitorCheck) break;
    }

    if (competitorCheck) {
      const conf = await confirmDialog(
        t(
          'Michelin does not guarantee the delivery of non-Michelin brand tires through its ' +
            'independent dealer network. By clicking Yes, your changes will be saved but you are ' +
            'acknowledging and agreeing that Michelin does not make this guarantee. To cancel your ' +
            'changes and return to this page, click No.',
        ),
        t('Save Changes'),
        t('Yes'),
        t('No'),
      );
      if (!conf) {
        setSaving(false);
        return;
      }
    }
    try {
      const res = await mutateTireProfile.mutateAsync({ accountNumber: selectedAccount, data, action });
      if (res && !res.isError) {
        if (action === 'edit') {
          reset(res.data as TireProfileOVM);
          history.push('view');
          enqueueSnackbar(t('Tire profile information saved.'), {
            variant: 'success',
            anchorOrigin: { vertical: 'top', horizontal: 'center' },
          });
        }
        if (action === 'add') {
          const hash_key = res.data?.hash_key?.split('~')[1] || '';
          history.push(`${hash_key}/view`);
          enqueueSnackbar(t('Tire Profile Was Created.'), {
            variant: 'success',
            anchorOrigin: { vertical: 'top', horizontal: 'center' },
          });
        }
      }
    } catch (error) {
      let errorInfo: Error | null = null;
      errorInfo = error as Error;
      let errorResponse: { isUniqueError: boolean; errorList?: string[]; uniqueErrorData?: UniqueErrorData } = {
        isUniqueError: false,
      };
      errorResponse = JSON.parse(errorInfo.message);
      if (!errorResponse.isUniqueError) {
        errorDialog(errorResponse.errorList, t('Error saving the information.'));
      } else if (errorResponse.isUniqueError) {
        errorDialog('Duplicate Profile.', t('Error saving the information.'));
      }
      setSaving(false);
    }
  };

  const onError = (errors: any) => {
    let msg = 'Please check the marked fields.';
    if (errors.axles && Object.keys(errors.axles).length > 0) {
      Object.keys(errors.axles).forEach((axle) => {
        if (errors.axles[axle].tire_replacement_priority?.message === 'Please add a Tire Replacement Priority.') {
          msg = 'Each axles must have at least one tire replacement priority defined';
        }
      });
    }
    errorDialog(t(`${msg}`).split('\n'), t('Missing requiered fields'));
  };

  // Actions Buttons Functions
  const handleFabBackClick = () => {
    history.push(`${getBaseUrl()}/tire-profile`);
  };

  const handleFabCancelClick = () => {
    if (action.toLowerCase() === 'edit') {
      history.push('view');
    } else {
      history.push(`${getBaseUrl()}/tire-profile`);
    }
  };
  return (
    <>
      <Container>
        <TabBar position="static">
          <Grid container spacing={2}>
            <Grid item xs={6} md={8} lg={9}>
              <Tabs
                value={selectedTab}
                onChange={(event: Object, newValue: number) => setSelectedTab(newValue)}
                aria-label="nav tabs"
                scrollButtons="on"
                variant="scrollable"
              >
                <Tab label={t('General Info')} value={0} required error={checkTabError('main')} />
                {axles?.all_axles.value && (
                  <Tab label={t('All')} value={1} required error={checkTabError('all_axles')} />
                )}
                {axles?.steer.value && <Tab label={t('Steer')} value={2} required error={checkTabError('steer')} />}
                {axles?.drive.value && <Tab label={t('Drive')} value={3} required error={checkTabError('drive')} />}
                {axles?.trailer.value && (
                  <Tab label={t('Trailer')} value={4} required error={checkTabError('trailer')} />
                )}
                {axles?.carry.value && <Tab label={t('Carry')} value={5} required error={checkTabError('carry')} />}
                {axles?.lift.value && <Tab label={t('Lift')} value={6} required error={checkTabError('lift')} />}
                {axles?.pusher.value && <Tab label={t('Pusher')} value={7} required error={checkTabError('pusher')} />}
                {axles?.tag.value && <Tab label={t('Tag')} value={8} required error={checkTabError('tag')} />}
              </Tabs>
            </Grid>
            <Grid item xs={6} md={4} lg={3}>
              <div style={{ width: '100%', textAlign: 'right', marginTop: selectedTab === 0 ? 0 : 0 }}>
                <Tooltip
                  arrow
                  title={
                    profileTypeSeted
                      ? t('Assign axle positions').toString()
                      : t('A Profile Type should be selected before assign axles').toString()
                  }
                >
                  <span>
                    <Button
                      style={{
                        marginBottom: selectedTab === 0 ? 0 : 0,
                        marginRight: selectedTab === 0 ? 0 : 0,
                      }}
                      disabled={action === 'view' || !profileTypeSeted}
                      color={!!tireProfile.axles && axleErrors && axleErrors.hasErrors ? 'danger' : 'primary'}
                      variant="contained"
                      size="small"
                      onClick={() => {
                        setShowAssignAxlePositionDialog(true);
                      }}
                    >
                      {t('Assign Axles')}
                    </Button>
                  </span>
                </Tooltip>
                <Tooltip
                  arrow
                  title={
                    selectedTab > 1
                      ? t('Clone {{axleName}} axle', {
                          axleName: getOptionLabel('axle_types', getAxleValueBySelectedTab(selectedTab)),
                        }).toString()
                      : ''
                  }
                >
                  <span>
                    <IconButton
                      disabled={action === 'view' || selectedTab < 2}
                      color="primary"
                      onClick={handleCloneAxle}
                    >
                      <FileCopyOutlined />
                    </IconButton>
                  </span>
                </Tooltip>
              </div>
            </Grid>
          </Grid>
        </TabBar>
        <TabPanel index={0} value={selectedTab}>
          <MainTab action={action} axles={axles} />
        </TabPanel>
        <TabPanel index={1} value={selectedTab} key={'all_axles'}>
          <AxleTab
            action={action}
            axleName={'all_axles'}
            products={products}
            addPriority={() => setShowAddTireSizeDialog(true)}
            selectedTab={selectedTab}
          />
        </TabPanel>
        <TabPanel index={2} value={selectedTab} key={'steer'}>
          <AxleTab
            action={action}
            axleName={'steer'}
            products={products}
            addPriority={() => setShowAddTireSizeDialog(true)}
            selectedTab={selectedTab}
          />
        </TabPanel>
        <TabPanel index={3} value={selectedTab} key={'drive'}>
          <AxleTab
            action={action}
            axleName={'drive'}
            products={products}
            addPriority={() => setShowAddTireSizeDialog(true)}
            selectedTab={selectedTab}
          />
        </TabPanel>
        <TabPanel index={4} value={selectedTab} key={'trailer'}>
          <AxleTab
            action={action}
            axleName={'trailer'}
            products={products}
            addPriority={() => setShowAddTireSizeDialog(true)}
            selectedTab={selectedTab}
          />
        </TabPanel>
        <TabPanel index={5} value={selectedTab} key={'carry'}>
          <AxleTab
            action={action}
            axleName={'carry'}
            products={products}
            addPriority={() => setShowAddTireSizeDialog(true)}
            selectedTab={selectedTab}
          />
        </TabPanel>
        <TabPanel index={6} value={selectedTab} key={'lift'}>
          <AxleTab
            action={action}
            axleName={'lift'}
            products={products}
            addPriority={() => setShowAddTireSizeDialog(true)}
            selectedTab={selectedTab}
          />
        </TabPanel>
        <TabPanel index={7} value={selectedTab} key={'pusher'}>
          <AxleTab
            action={action}
            axleName={'pusher'}
            products={products}
            addPriority={() => setShowAddTireSizeDialog(true)}
            selectedTab={selectedTab}
          />
        </TabPanel>
        <TabPanel index={8} value={selectedTab} key={'tag'}>
          <AxleTab
            action={action}
            axleName={'tag'}
            products={products}
            addPriority={() => setShowAddTireSizeDialog(true)}
            selectedTab={selectedTab}
          />
        </TabPanel>
      </Container>

      <AssignAxlePositions
        open={showAssignAxlePositionDialog}
        close={() => setShowAssignAxlePositionDialog(false)}
        axles={axles}
        axlesOnChange={setAxles}
        setSelectedTab={setSelectedTab}
        selectedTab={selectedTab}
      />

      <CloneAxlePositions
        axleName={selectedAxle}
        open={showCloneAxlePositionDialog}
        onClose={() => setShowCloneAxlePositionDialog(false)}
        axles={axles}
        onClone={setAxles}
      />
      <AddTireSize
        open={showAddTireSizeDialog}
        close={() => setShowAddTireSizeDialog(false)}
        axleName={selectedAxle}
        products={products}
      />

      <GlobalActions>
        <Fab
          color="primary"
          aria-label={t('Add Tire Replacement Priority')}
          onClick={() => setShowAddTireSizeDialog(true)}
          disabled={
            action === 'view' ||
            !allowsEdit ||
            selectedTab === 0 ||
            formState.isSubmitting ||
            mutateTireProfile.isLoading
          }
        >
          <Add />
        </Fab>
        <Fab
          color="primary"
          aria-label={t('Edit')}
          onClick={() => history.push('edit')}
          disabled={action !== 'view' || !allowsEdit}
        >
          <Edit />
        </Fab>
        <Fab
          color="inherit"
          aria-label={t('Saving')}
          disabled
          displayDisabled={formState.isSubmitting || mutateTireProfile.isLoading}
        >
          <Timelapse />
        </Fab>
        <Fab
          color={isDirty ? 'primary' : 'inherit'}
          aria-label={t('Save')}
          onClick={!isDirty ? () => {} : methods.handleSubmit(save, onError)}
          disabled={formState.isSubmitting || mutateTireProfile.isLoading || action === 'view' || !isDirty}
          displayDisabled={action === 'add' || action === 'edit'}
        >
          <Save />
        </Fab>
        <Fab
          color="primary"
          aria-label={t('Cancel')}
          onClick={handleFabCancelClick}
          disabled={formState.isSubmitting || mutateTireProfile.isLoading || action === 'view'}
        >
          <Cancel />
        </Fab>
        <Fab color="primary" aria-label={t('Back')} onClick={handleFabBackClick} disabled={action !== 'view'}>
          <ArrowBack />
        </Fab>
      </GlobalActions>
      <PreventTransitionPrompt when={isDirty && !saving && action !== 'view'} handleDiscard={() => reset()} />
    </>
  );
}

export function TireProfileDetails({ action }: { action: Actions }) {
  const { t } = useTranslation();
  const history = useHistory();
  const { profileId, selectedAccount } = useParams<TireProfileParams>();
  const { enqueueSnackbar } = useSnackbar();
  const permissions = usePermissions();
  const tirePermisions = {
    read: permissions.allowsAction('wheels.read'),
    create: permissions.allowsAction('wheels.create'),
    update: permissions.allowsAction('wheels.update'),
  };

  // Control permissions
  if (tirePermisions && tirePermisions.read === false) {
    enqueueSnackbar(t('Your user does not have enough permissions to read the information about this wheel profile.'), {
      variant: 'warning',
    });
    history.push(`${getBaseUrl()}/tire-profile`);
  } else if (tirePermisions && tirePermisions.update === false && action.toLowerCase() === 'edit') {
    enqueueSnackbar(t('Your user does not have enough permissions to edit the information about this wheel profile.'), {
      variant: 'warning',
    });
    history.push(`${getBaseUrl()}/tire-profile/${profileId}/view`);
  } else if (tirePermisions && tirePermisions.create === false && action.toLowerCase() === 'add') {
    enqueueSnackbar(t('Your user does not have enough permissions to create a wheel profile.'), { variant: 'warning' });
    history.push(`${getBaseUrl()}/tire-profile`);
  }

  const getTireProfile = useGetTireProfile(profileId, selectedAccount, action);
  const products = useGetProducts(selectedAccount);

  const methods = useForm<TireProfileOVM>({ mode: 'onChange', defaultValues: {} });

  useEffect(() => {
    if (getTireProfile.isFetching || !getTireProfile.data) return;
    methods.reset(getTireProfile.data);
  }, [getTireProfile.isFetching, getTireProfile.data, methods]);

  if (
    getTireProfile.isLoading ||
    getTireProfile.isFetching ||
    products.isLoading ||
    products.isFetching ||
    Object.keys(methods.getValues()).length === 0
  ) {
    return (
      <Panel>
        <LoadingBounce style={{ height: '80vh' }} />
      </Panel>
    );
  }

  if (getTireProfile.isError || products.isError) {
    return (
      <>
        <Panel variant="none" style={{ border: 0 }}>
          <Grid container>
            <Grid item xs={12} spacing={2}>
              <PlainMessage
                title={t('Database Error')}
                messages={[
                  t('Application could not load tire data.'),
                  t('Please try again later or contact support if the error persists'),
                ]}
              />
            </Grid>
          </Grid>
        </Panel>
        <GlobalActions>
          <Fab color="primary" aria-label={t('Back')} onClick={() => history.push(`${getBaseUrl()}/tire-profile`)}>
            <ArrowBack />
          </Fab>
        </GlobalActions>
      </>
    );
  }

  return (
    <>
      <PageTitle
        title={t('Tire Profile')}
        subtitle={methods.getValues().main?.profile_name}
        rightItems={<LastModified entity="tire_profile" identifier={methods.getValues().id} />}
      />
      <FormProvider {...methods}>
        <Details action={action} methods={methods} products={products.data} />
      </FormProvider>
    </>
  );
}
